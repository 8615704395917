import i18n from '@/plugins/i18n'
import store from '@/store'
import pushNotificationsRoutes from '@/modules/pushNotifications/organizationRouter'
import guildMatchesRouter from '@/modules/guildMatches/organizationRouter'
import sidebarItems from './sidebarItems'

export default [
  {
    path: '/organization/:organizationId',
    name: 'organization',
    component: () => import('@/modules/organization/Organization'),
    redirect: { name: 'organization-projects' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId } = to.params

      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        const entity = await store.dispatch('organization/bind', organizationId)
        if (!entity) next({ name: '404' })
        await store.dispatch('organization/bindEventLog', organizationId)
        await store.dispatch('organization/bindClusters', organizationId)
        await store.dispatch('organization/bindProjects', organizationId)
        await store.dispatch('organization/bindAdmins', organizationId)
        await store.dispatch('organization/bindTerms', organizationId)
        await store.dispatch('stats/bindStats', organizationId)
        if (store.state.organization?.dbData?.sport === 'guild') await store.dispatch('guildMatches/bindGuildMatches', organizationId)
      } else {
        if (!store.state.organization?.dbEventLog?.length) await store.dispatch('organization/bindEventLog', organizationId)
        if (!store.state.organization?.dbClusters?.length) await store.dispatch('organization/bindClusters', organizationId)
        if (!store.state.organization?.dbProjects?.length) await store.dispatch('organization/bindProjects', organizationId)
        if (!store.state.organization?.dbAdmins?.length) await store.dispatch('organization/bindAdmins', organizationId)
        if (!store.state.organization?.dbTerms?.length) await store.dispatch('organization/bindTerms', organizationId)
        if (!store.state.stats?.dbStats?.length) await store.dispatch('stats/bindStats', organizationId)
        if (store.state.organization?.dbData?.sport === 'guild'
        && !store.state.guildMatches?.dbGuildMatches?.length) await store.dispatch('guildMatches/bindGuildMatches', organizationId)
      }
      store.commit('loader/hide')

      // Security
      const userOrganizationIds = [...store.getters['user/adminOrganizationIds'], ...store.getters['user/collabOrganizationIds']]
      const isUserOrganizationStaff = userOrganizationIds.includes(to.params.organizationId)
      if (!(isUserOrganizationStaff || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      const section = sidebarItems.find(item => item.route?.name === to.name)
      if (section) {
        if (store.getters['organization/sidebarItems'].find(item => item.id === section.id)) next()
        else next({ name: '404' })
      } else next()
    },
    children: [
      {
        path: 'settings',
        name: 'organization-settings',
        component: () => import('@/modules/organization/Settings'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.settings'),
        },
        props: true,
      },
      {
        path: 'terms',
        name: 'organization-terms',
        component: () => import('@/modules/organization/Terms'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.terms')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.terms'),
        },
        props: true,
      },
      {
        path: 'projects',
        name: 'organization-projects',
        component: () => import('@/modules/organization/ProjectList'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.projects')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.projects'),
        },
        props: true,
      },
      {
        path: 'log',
        name: 'organization-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.eventLog'),
        },
        props: true,
      },
      {
        path: 'staff-list/:typeId',
        name: 'organization-staff-list',
        component: () => import('@/modules/organization/StaffList'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.people')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.people'),
        },
        props: true,
      },
      {
        path: 'stats',
        name: 'organization-statistics',
        component: () => import('@/modules/stats/Stats'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.stats')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.stats'),
        },
        props: true,
      },
      ...pushNotificationsRoutes,
      ...guildMatchesRouter,
    ],
  },
]
