export default {
  ADMIN: 'admin',
  COLLABORATOR: 'collaborator',
  COACH: 'coach',
  PARENT: 'parent',
  PLAYER: 'player',
  FAN: 'fan',
  SUBSCRIBER: 'subscriber',
  MANAGER: 'manager',
  SUBMANAGER: 'submanager',
  MATCHEDITOR: 'matchEditor',
}
