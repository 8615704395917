import { firestoreAction } from 'vuexfire'
import { db } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbOrganizations: [],
  }),
  getters: {
    isUserSuperadmin: (state, getters, rootState) => !!rootState.user.dbData?.roles?.roles?.includes('superadmin'),
    organizations: (state, getters, rootState) => state.dbOrganizations.map(organization => ({
      ...organization,
      isFavorite: rootState.user.dbData?.favorites?.organizations?.includes(organization.id),
    })),
  },
  actions: {
    bindOrganizations: firestoreAction(({ bindFirestoreRef }) => bindFirestoreRef('dbOrganizations', db.collection('properties'))),
  },
}
