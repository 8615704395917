import i18n from '@/plugins/i18n'
import store from '@/store'
import activityRoutes from '@/modules/activity/router'
import competitionRoutes from '@/modules/competition/router'
import projectUsers from '@/modules/projectUsers/router'
import postRoutes from '@/modules/post/router'
import calendarRoutes from '@/modules/calendar/router'
import sidebarItems from './sidebarItems'
import pushNotificationsRoutes from '@/modules/pushNotifications/projectRouter'

export default [
  {
    path: '/organization/:organizationId/project/:projectId',
    name: 'project',
    component: () => import('@/modules/project/Project'),
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId } = to.params
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('organization/bind', organizationId)
        await store.dispatch('organization/bindEventLog', organizationId)
      }
      const isBasketball = store.getters['organization/isBasketball']
      if (store.state.project.dbData?.id !== projectId) {
        const project = await store.dispatch('project/bind', { organizationId, projectId })
        if (!project) next({ name: '404' })
        await store.dispatch('post/unbindPosts')
        await store.dispatch('dynamicForm/bind', { organizationId, projectId })
        await store.dispatch('projectUsers/unbindPlayers')
        await store.dispatch('project/bindEnrollments', { organizationId, projectId })
        await store.dispatch('project/bindActivities', { organizationId, projectId })
        await store.dispatch('project/bindTerms', { organizationId, projectId })
        if (project?.type === 'competition') {
          await store.dispatch('competition/bindStages', { organizationId, projectId })
          await store.dispatch('competition/bindRegions', { organizationId, projectId })
          await store.dispatch('competition/bindCategories', { organizationId, projectId })
          await store.dispatch('competition/bindCohorts', { organizationId, projectId })
          await store.dispatch('competition/bindClubs', { organizationId, projectId })
          await store.dispatch('competition/bindClubEnrollments', { organizationId, projectId })
          await store.dispatch('project/bindServices', { organizationId, projectId })
          await store.dispatch('competition/bindMatchLocations', { organizationId, projectId })
          await store.dispatch('competition/bindBanTypes', { organizationId, projectId })
          await store.dispatch('competition/bindEvents', { projectId, typeId: 'red_card' })
          await store.dispatch('competition/bindPlayers', { projectId })
          if (isBasketball) await store.dispatch('competition/bindFranchises', { organizationId })
          await store.dispatch('competition/bindGroups', { organizationId, projectId })
          await store.dispatch('competition/bindTeams', { organizationId, projectId })
          await store.dispatch('competition/bindRounds', { organizationId, projectId })
          await store.dispatch('competition/bindMatches', { organizationId, projectId })
          await store.dispatch('clubEnrollmentForm/bindTemplate', { organizationId, projectId })
        }
        if (project?.type === 'academy') {
          await store.dispatch('academy/bindCategories', { organizationId, projectId })
          await store.dispatch('academy/bindTeams', { organizationId, projectId })
          await store.dispatch('academy/bindParents', { organizationId, projectId })
        }
        if (project?.clusterId) await store.dispatch('cluster/bind', { organizationId, clusterId: project.clusterId })
      } else {
        const project = store.state.project.dbData
        if (!store.state.project.dbEnrollments.length) await store.dispatch('project/bindEnrollments', { organizationId, projectId })
        if (!store.state.project.dbActivities.length) await store.dispatch('project/bindActivities', { organizationId, projectId })
        if (!store.state.project.dbTerms.length) await store.dispatch('project/bindTerms', { organizationId, projectId })
        if (project?.type === 'competition') {
          if (!store.state.competition.dbCategories.length) await store.dispatch('competition/bindCategories', { organizationId, projectId })
          if (!store.state.competition.dbRegions.length) await store.dispatch('competition/bindRegions', { organizationId, projectId })
          if (!store.state.competition.dbClubs.length) await store.dispatch('competition/bindClubs', { organizationId, projectId })
          if (!store.state.competition.dbClubEnrollments.length) await store.dispatch('competition/bindClubEnrollments', { organizationId, projectId })
          if (!store.state.competition.dbCohorts.length) await store.dispatch('competition/bindCohorts', { organizationId, projectId })
          if (!store.state.competition.dbStages.length) await store.dispatch('competition/bindStages', { organizationId, projectId })
          if (!store.state.project.dbServices.length) await store.dispatch('project/bindServices', { organizationId, projectId })
          if (!store.state.competition.dbMatchLocations.length) await store.dispatch('competition/bindMatchLocations', { organizationId, projectId })
          if (!store.state.competition.dbBanTypes.length) await store.dispatch('competition/bindBanTypes', { organizationId, projectId })
          if (!store.state.competition.dbEvents.length) await store.dispatch('competition/bindEvents', { projectId, typeId: 'red_card' })
          if (!store.state.competition.dbPlayers.length) await store.dispatch('competition/bindPlayers', { projectId })
          if (isBasketball && !store.state.competition.dbFranchises.length) await store.dispatch('competition/bindFranchises', { organizationId })
          if (!store.state.competition.dbGroups.length) await store.dispatch('competition/bindGroups', { organizationId, projectId })
          if (!store.state.competition.dbTeams.length) await store.dispatch('competition/bindTeams', { organizationId, projectId })
          if (!store.state.competition.dbRounds.length) await store.dispatch('competition/bindRounds', { organizationId, projectId })
          if (!store.state.competition.dbMatches.length) await store.dispatch('competition/bindMatches', { organizationId, projectId })
          if (!store.state.clubEnrollmentForm.dbTemplate) await store.dispatch('clubEnrollmentForm/bindTemplate', { organizationId, projectId })
        }
        if (project?.type === 'academy') {
          if (!store.state.academy.dbCategories.length) await store.dispatch('academy/bindCategories', { organizationId, projectId })
          if (!store.state.academy.dbTeams.length) await store.dispatch('academy/bindTeams', { organizationId, projectId })
          if (!store.state.academy.dbParents.length) await store.dispatch('academy/bindParents', { organizationId, projectId })
        }
      }
      store.commit('loader/hide')

      // Security
      const isUserOrganizationAdmin = store.getters['user/adminOrganizationIds'].includes(to.params.organizationId)
      const isUserProjectCollab = store.getters['user/collabProjectIds'].includes(to.params.projectId)
      const project = store.state.project.dbData
      const isUserClusterManager = store.getters['user/managerClusterIds'].includes(project?.clusterId)
      if (!(isUserOrganizationAdmin || isUserProjectCollab || isUserClusterManager || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      const section = sidebarItems.find(item => item.route?.name === to.name)
      if (section) {
        if (store.getters['project/sidebarItems'].find(item => item.id === section.id)) next()
        else next({ name: '404' })
      } else if (to.name === 'project') {
        const filteredSidebarItems = store.getters['project/sidebarItems']
        const { route } = filteredSidebarItems.find(item => item.route)
        next({ ...route, params: { organizationId, projectId } })
      } else next()
    },
    children: [
      {
        path: 'settings',
        name: 'project-edit',
        component: () => store.getters['project/isGuild'] ? import('@/modules/guild/EditGuild') : import('@/modules/project/EditProject'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.settings'),
        },
        props: true,
      },
      {
        path: 'sponsorization',
        name: 'project-sponsorization',
        component: () => import('@/modules/project/SponsorizationProject'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.sponsorization'),
        },
        props: true,
      },
      {
        path: 'categories-and-teams',
        name: 'project-categories-and-teams',
        component: () => import('@/modules/academy/CategoriesAndTeams'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.categories')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.categories'),
        },
        props: true,
      },
      {
        path: 'waiver',
        name: 'project-waiver',
        component: () => import('@/modules/project/Waivers'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.waiver')} - OWQLO`,
          headerTitle: () => i18n.t('common.waiver'),
        },
        props: true,
      },
      {
        path: 'form',
        name: 'project-form',
        component: () => import('@/modules/dynamicForm/DynamicFormConfig'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.form')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.form'),
        },
        props: true,
      },
      {
        path: 'payments',
        name: 'project-payments',
        component: () => import('@/modules/project/Payments'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.payments')} - OWQLO`,
          headerTitle: () => i18n.t('common.payments'),
        },
        props: true,
      },
      {
        path: 'enrollments',
        name: 'project-enrollments',
        component: () => import('@/modules/project/EnrollmentList'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.enrollments')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.enrollments'),
        },
        props: true,
      },
      {
        path: 'emails',
        name: 'project-emailAdmission',
        component: () => import('@/modules/project/EmailSettings'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.emailAdmission'),
        },
        props: true,
      },
      {
        path: 'log',
        name: 'project-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.eventLog'),
        },
        props: true,
      },
      {
        path: 'calendar/day/:date',
        name: 'project-calendar',
        component: () => import('@/modules/project/ActivityList'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.activities')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.activities'),
        },
        props: true,
      },
      {
        path: 'create-activity',
        name: 'project-create-activity',
        component: () => import('@/modules/activity/EditActivity'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.createActivity')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.createActivity'),
        },
        props: true,
      },
      ...activityRoutes,
      ...competitionRoutes,
      ...projectUsers,
      ...postRoutes,
      ...calendarRoutes,
      ...pushNotificationsRoutes,
    ],
  },
]
