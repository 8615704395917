import Vue from 'vue'
import '@/setEnv'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import '@/utils/mixins/global'
import vuetify from '@/plugins/vuetify'
import papaparse from '@/plugins/papaparse'
import presenterFactory from '@/plugins/presenterFactory'
import i18n from '@/plugins/i18n'
import vuelidate from '@/plugins/vuelidate'
import '@/plugins/vuefileagent'
import '@/plugins/vueChatScroll'
import { auth } from '@/services/firebase'

Vue.prototype.isDev = window.isDev
Vue.config.productionTip = false
Vue.config.devtools = true

auth.onAuthStateChanged(async currentUser => {
  store.commit('loader/show')
  if (currentUser) {
    // In case user logs in with a different user in another tab
    if (store.state.user.dbData?.id && !['login', 'register'].includes(router.currentRoute.name)) router.push({ name: 'login' })
    await store.dispatch('user/bind', currentUser.uid)
  } else await store.dispatch('user/unbind')
  store.commit('loader/hide')
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    vuelidate,
    presenterFactory,
    papaparse,
    render: h => h(App),
  }).$mount('#app')
})
