import i18n from '@/plugins/i18n'
import store from '@/store'
import { auth } from '@/services/firebase'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/user/Login'),
    meta: {
      title: () => `${i18n.t('user.login')} - OWQLO`,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/modules/user/Logout'),
    meta: {
      title: () => `${i18n.t('user.logout')} - OWQLO`,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/modules/user/Register'),
    meta: {
      title: () => `${i18n.t('user.register')} - OWQLO`,
    },
  },
  {
    path: '/redirect-after-login',
    name: 'redirectAfterLogin',
    redirect: () => {
      if (!auth.currentUser) return { name: 'login' }
      if (store.getters['superadmin/isUserSuperadmin']) return { name: 'superadmin' }
      const userOrganizationIds = [...store.getters['user/adminOrganizationIds'], ...store.getters['user/collabOrganizationIds']]
      if (userOrganizationIds.length) return { name: 'organization-projects', params: { organizationId: userOrganizationIds[0] } }
      const userClubs = store.getters['user/collabClubs']
      if (userClubs.length) {
        const { organizationId, projectId, id: clubId } = userClubs[0]
        return { name: 'club-edit', params: { organizationId, projectId, clubId } }
      }
      return { name: '404' }
    },
  },
  {
    path: '/login-club/:organizationId/:projectId/:clubId',
    name: 'login-club',
    component: () => import('@/modules/user/LoginClub'),
    meta: {
      title: () => `${i18n.t('user.login')} - OWQLO`,
    },
    props: true,
  },
  {
    path: '/purchase/:purchaseId',
    name: 'purchase-delivered',
    component: () => import('@/modules/user/PurchaseDelivered'),
    meta: { title: () => `${i18n.t('user.purchase.deliverProduct')} - OWQLO` },
    props: true,
  },
]
