import { db, FieldValue, uploadFileAndGetUrl, callBackend } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import logEvent from '@/utils/logEvent'

export default {
  namespaced: true,
  actions: {
    // Create
    async create(context, { organizationId, clusterId, data }) {
      const guildRef = db.collection(`properties/${organizationId}/projects`).doc()
      const id = guildRef.id
      const guild = {
        ...data,
        id,
        clusterId,
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await guildRef.set(guild)

      const fields = (await db.collection(`properties/${organizationId}/forms`).doc('enrollment').get()).data()
      const enrollmentRef = db.collection(`properties/${organizationId}/projects/${id}/forms`).doc('enrollment')
      await enrollmentRef.set(fields)
      return true
    },
    // Update
    async update(context, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)

      const ads = data.ads ? {
        link: data.ads.link ?? null,
        full: await uploadFileAndGetUrl(storagePath, data.ads.full),
        footer: await uploadFileAndGetUrl(storagePath, data.ads.footer),
      } : null
      const dataToUpdate = { ...data, ...(ads && { ads }), logo, banner }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
      return true
    },
  },
}
