import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'

export default {
  namespaced: true,
  state: () => ({
    dbStages: [],
    dbRegions: [],
    dbCategories: [],
    dbClubs: [],
    dbClubEnrollments: [],
    dbCohorts: [],
    dbGroups: [],
    dbRounds: [],
    dbTeams: [],
    dbPlayers: [],
    dbFixtures: [],
    dbMatches: [],
    dbFranchises: [],
    dbMatchLocations: [],
    dbBanTypes: [],
    dbEvents: [],
  }),
  getters: {
    stages: state => state.dbStages,
    hasRrobinSubgroups: (state, getters) => getters.stages.find(s => s.id === 'rrobin')?.hasSubgroups ?? false,
    isRegularNba: (state, getters, rootState, rootGetters) => rootGetters['organization/isBasketball'] && getters.hasRrobinSubgroups,
    clubs: state => state.dbClubs,
    clubEnrollments: state => state.dbClubEnrollments,
    cohorts: (state, getters, rootState, rootGetters) => state.dbCohorts.map(cohort => {
      const region = getters.regions.find(r => r.id === cohort.regionId)
      const category = getters.categories.find(c => c.id === cohort.categoryId)
      return {
        ...cohort,
        name: `${rootGetters['project/data'].features?.hasRegions && region?.name ? `${region.name} - ` : ''}${category?.name ? `${category.name}` : ''}`,
      }
    }),
    matches: state => state.dbMatches,
    locations: state => state.dbLocations,
    regions: state => [...state.dbRegions].sort((a, b) => a?.name.localeCompare(b?.name)),
    categories: state => [...state.dbCategories].sort((a, b) => a?.name.localeCompare(b?.name)),
    groups: state => [...state.dbGroups].sort((a, b) => a?.index - b?.index),
    rounds: state => state.dbRounds,
    teams: (state, getters, rootState, rootGetters) => state.dbTeams.map(team => ({
      ...team,
      name: `${getters.clubs.find(c => c.id === team.clubId).name}${team.suffix ? ` - ${team.suffix}` : ''}`,
      nameWithCohort: `${getters.cohorts.find(c => c.id === team.cohortId)?.name}${team.suffix ? ` - ${team.suffix}` : ''}`,
      logo: rootGetters['organization/isBasketball']
        ? getters.franchises.find(f => f.id === team?.franchiseId)?.logo?.thumbUrl
        : getters.clubs.find(club => club.id === team?.clubId)?.logo,
      clubId: team.clubId,
    })),
    players: state => state.dbPlayers,
    franchises: state => state.dbFranchises,
    matchLocations: state => state.dbMatchLocations,
    banTypes: state => state.dbBanTypes,
    events: state => state.dbEvents,
  },
  actions: {
    // bind
    bindStages: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbStages',
      db.collection(`properties/${organizationId}/projects/${projectId}/stages`),
    )),
    bindRegions: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbRegions',
      db.collection(`properties/${organizationId}/projects/${projectId}/regions`),
    )),
    bindCategories: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCategories',
      db.collection(`properties/${organizationId}/projects/${projectId}/categories`),
    )),
    bindFranchises: firestoreAction(({ bindFirestoreRef }, { organizationId }) => bindFirestoreRef(
      'dbFranchises',
      db.collection(`properties/${organizationId}/franchises`),
    )),
    bindMatchLocations: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbMatchLocations',
      db.collection(`properties/${organizationId}/projects/${projectId}/matchLocations`),
    )),
    bindClubs: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbClubs',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubs`),
    )),
    bindClubEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbClubEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`),
    )),
    bindBanTypes: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbBanTypes',
      db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`),
    )),
    bindCohorts: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCohorts',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`),
    )),
    //  Cohorts collection groups
    bindTeams: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbTeams',
      db.collectionGroup('teams').where('projectId', '==', projectId),
    )),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbPlayers',
      db.collectionGroup('players').where('projectId', '==', projectId),
    )),
    bindGroups: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbGroups',
      db.collectionGroup('groups').where('projectId', '==', projectId),
    )),
    bindRounds: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbRounds',
      db.collectionGroup('rounds').where('projectId', '==', projectId),
    )),
    bindMatches: firestoreAction(({ bindFirestoreRef }, { projectId }) => bindFirestoreRef(
      'dbMatches',
      db.collectionGroup('matches').where('projectId', '==', projectId),
    )),
    bindEvents: firestoreAction(({ bindFirestoreRef }, { projectId, typeId }) => bindFirestoreRef(
      'dbEvents',
      db.collectionGroup('events').where('projectId', '==', projectId).where('typeId', '==', typeId),
    )),

    // unbind
    unbindRegions: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbRegions')),
    unbindCategories: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbCategories')),
    unbindClubs: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindClubs')),
    unbindCohorts: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindCohorts')),
    unbindStages: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindStages')),
    unbindGroups: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindGroups')),
    unbindRounds: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindRounds')),
    unbindTeams: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindTeams')),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindPlayers')),
    unbindFixtures: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindFixtures')),
    unbindMatches: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('bindMatches')),

    getMatchesCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-matches-csv', { organizationId, projectId }),
    getTeamsCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-teams-csv', { organizationId, projectId }),
    getPlayersCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-players-csv', { organizationId, projectId }),
    getClubsCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-clubs-csv', { organizationId, projectId }),
    getClubEnrollmentsCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-clubEnrollments-csv', { organizationId, projectId }),
    getTeamEnrollmentsCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-teamEnrollments-csv', { organizationId, projectId }),
    getRrobinStatsCsv: (context, { organizationId, projectId }) => callBackend('exports/competition/get-rrobin-stats-csv', { organizationId, projectId }),
    canMatchBeEdited: (context, { organizationId, projectId, cohortId, matchId }) => callBackend('projects/competition/can-match-be-edited', { organizationId, projectId, cohortId, matchId }),

    approveClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return callBackend('clubs/approve-enrollment', { organizationId, projectId, clubEnrollmentId })
    },
    rejectClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return callBackend('clubs/reject-enrollment', { organizationId, projectId, clubEnrollmentId })
    },
    mergeWithOtherClub: (context, { organizationId, projectId, baseClubId, extraClubId }) => callBackend('clubs/merge', { organizationId, projectId, baseClubId, extraClubId }),
    makePendingClubEnrollment(context, { organizationId, projectId, clubEnrollmentId }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`).doc(clubEnrollmentId).update({ status: 'pendingApproval' })
    },

    // Create
    async createRegion(context, { organizationId, projectId, regionId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).set(data)
    },

    async createCategory(context, { organizationId, projectId, categoryId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).set(data)
    },

    async createCohort(context, { organizationId, projectId, cohortId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).set(data)
    },

    async createMatchLocation(context, { organizationId, projectId, data }) {
      const ref = db.collection(`properties/${organizationId}/projects/${projectId}/matchLocations`).doc(strToUniqueId(data.name))
      await ref.set({ id: ref.id, ...data })
      return true
    },

    async createBanType(context, { organizationId, projectId, data }) {
      const ref = await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(strToUniqueId(data.reason))
      await ref.set({ id: ref.id, ...data })
      return true
    },

    // Update
    async updateRegion(context, { organizationId, projectId, regionId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).update(data)
    },

    async updateCategory(context, { organizationId, projectId, categoryId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).update(data)
    },

    async updatePlayerBanMatchCount(context, { organizationId, projectId, cohortId, id, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players`).doc(id).update(data)
      return true
    },

    async updateEventBanMatchCount(context, { organizationId, projectId, cohortId, matchId, id, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches/${matchId}/events`).doc(id).update(data)
      return true
    },

    // update matches
    async updateMatch({ state }, { organizationId, projectId, cohortId, matchId, data }) {
      console.log('🚀 ~ file: store.js ~ line 257 ~ updateMatch ~ data', data)
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId).update(dataToUpdate)
      return true
    },

    async updateMatchLocation(context, { organizationId, projectId, matchLocationId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/matchLocations`).doc(matchLocationId).update(data)
      return true
    },

    async finalizeMatch({ rootGetters }, { organizationId, projectId, cohortId, matchId }) {
      const matchRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/matches`).doc(matchId)
      try {
        await matchRef.update({ finishedAt: FieldValue.serverTimestamp() })
        await callBackend('projects/competition/finish-match', { organizationId, projectId, cohortId, matchId })
        await matchRef.update({ finishedBy: rootGetters['user/data'].id })
        return true
      } catch (err) {
        await matchRef.update({ finishError: JSON.stringify(err) })
        return false
      }
    },

    recalculateAfterMatchEdit: ({ rootGetters }, { organizationId, projectId, cohortId, matchId }) => callBackend('projects/competition/recalculate-after-match-edit', { organizationId, projectId, cohortId, matchId }),

    async updateBanType(context, { organizationId, projectId, banTypeId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(banTypeId).update(data)
      return true
    },

    async matchChangeTeam({ getters }, { organizationId, projectId, match, teamIndex, newTeam }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const oldTeam = getters.teams.find(t => t.id === match[`team${teamIndex}`].id && t.cohortId === match.cohortId)
      await projectRef.collection(`cohorts/${match.cohortId}/matches`).doc(match.id).update({
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        [`teamsById.${newTeam.id}`]: oldTeam?.id ? match.teamsById[oldTeam.id] : { index: teamIndex },
        clubIds: match.clubIds.filter(id => id !== oldTeam?.clubId).concat(newTeam.clubId),
      })
      await projectRef.collection(`cohorts/${match.cohortId}/fixtures`).doc(match.fixtureId).update({
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        [`teamsById.${newTeam.id}`]: oldTeam?.id ? match.teamsById[oldTeam.id] : { index: teamIndex },
      })
      return true
    },

    async matchChangeTeamOrigin({ getters }, { organizationId, projectId, match, teamIndex, newOrigin }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      const oldOrigin = match[`team${teamIndex}`].origin
      const oldTeam = getters.teams.find(t => t.id === match[`team${teamIndex}`].id && t.cohortId === match.cohortId)
      await projectRef.collection(`cohorts/${match.cohortId}/matches`).doc(match.id).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        [`teamsByOrigin.${newOrigin.id}-position${newOrigin.position}`]: newOrigin,
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
        ...(oldTeam?.id && { clubIds: FieldValue.arrayRemove(oldTeam?.clubId) }),
      })
      await projectRef.collection(`cohorts/${match.cohortId}/fixtures`).doc(match.fixtureId).update({
        ...(oldOrigin && { [`teamsByOrigin.${oldOrigin.id}-position${oldOrigin.position}`]: FieldValue.delete() }),
        [`teamsByOrigin.${newOrigin.id}-position${newOrigin.position}`]: newOrigin,
        ...(oldTeam?.id && { [`teamsById.${oldTeam.id}`]: FieldValue.delete() }),
      })
    },
    async updateGroup(context, { organizationId, projectId, cohortId, groupId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/groups`).doc(groupId).update(data)
      return true
    },

    // Delete
    async deleteRegion(context, { organizationId, projectId, regionId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/regions`).doc(regionId).delete()
    },

    async deleteCategory(context, { organizationId, projectId, categoryId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/categories`).doc(categoryId).delete()
    },

    async deleteCohort(context, { organizationId, projectId, cohortId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts`).doc(cohortId).delete()
    },

    async deleteMatchLocation(context, { organizationId, projectId, matchLocationId }) {
      const matchRefs = (await db.collectionGroup('matches').where('locationId', '==', matchLocationId).where('projectId', '==', projectId).get())
        .docs.map(s => s.ref)
      await Promise.all(matchRefs.map(ref => ref.update({ locationId: null, fieldId: null })))

      await db.collection(`properties/${organizationId}/projects/${projectId}/matchLocations`).doc(matchLocationId).delete()
      return true
    },

    async deleteReferenceFieldMatchLocation(context, { projectId, matchLocationId, fieldId }) {
      const matchRefs = (await db.collectionGroup('matches').where('locationId', '==', matchLocationId).where('fieldId', '==', fieldId)
        .where('projectId', '==', projectId).get())
        .docs.map(s => s.ref)
      await Promise.all(matchRefs.map(ref => ref.update({ fieldId: null })))
      return true
    },

    async deleteBanType(context, { organizationId, projectId, banTypeId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/banTypes`).doc(banTypeId).delete()
      return true
    },
  },
}
