<template>
  <div class="text-center">
    <v-overlay :value="loader.show" :z-index="303" :opacity="0.5" color="white">
      <v-progress-circular indeterminate size="64" color="primary" />
      <div v-if="loader.message" class="mt-8 pa-4 text-h6 white--text message" v-text="loader.message" />
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['loader']),
  },
}
</script>

<style lang="scss" scoped>
.message {
  background-color: black;
  border-radius: 8px;
  opacity: 0.6;
}
</style>
