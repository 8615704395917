import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl, runCloudFunction } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import firebaseConfig from '@/config/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbTemplate: null,
  }),
  getters: {
    template: state => state.dbTemplate,
  },
  actions: {
    // Read
    bindTemplate: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbTemplate',
      db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('clubEnrollment'),
    )),

    // Create
    async create({ rootState, rootGetters }, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/clubEnrollments/${data.id}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      await db.collection(`properties/${organizationId}/projects/${projectId}/clubEnrollments`).doc(data.id).set({
        ...data,
        logo,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      // Send email
      if (!rootState.project.dbData.email) return true
      const adminUrl = firebaseConfig.projectId === 'owqlo-master-production-id' ? 'https://admin.owqlo.com' : 'https://admin-dev.owqlo.com'
      const payload = {
        templateId: 'd-2a367244a8e94dd1a1cf6bf893bd86f3',
        email: rootState.project.dbData.email,
        dynamicTemplateData: {
          projectName: rootState.project.dbData.name,
          lang: rootState.project.dbData.language,
          clubName: data.name,
          clubEnrollmentLink: `${adminUrl}/organization/${organizationId}/project/${projectId}/club-enrollments?clubEnrollmentId=${data.id}`,
          teamsInfo: Object.entries(data.teamCountByCohortId).map(([cohortId, count]) => `
            ${i18n.t('common.category')}: <b>${rootGetters['competition/cohorts'].find(c => c.id === cohortId).name}</b><br>${i18n.t('team.count')}: <b>${count}</b>`).join('<br><br>'),
        },
      }
      runCloudFunction('sendDynamicTemplateEmail', payload)
      return true
    },
    // update
    async createOrUpdateClubEnrollmentForm({ state }, { organizationId, projectId, data }) {
      const ref = db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('clubEnrollment')
      if (state.dbTemplate !== null) await ref.update(data)
      else await ref.set(data)
      return true
    },
  },
}
