import staffRoles from '@/enums/staffRoles'

export default (role, proyectType) => {
  if (!Object.keys(staffRoles).includes(role)) {
    throw Error(`unknown role: ${role} available roles [${Object.keys(staffRoles)}]`)
  }
  if (!proyectType) {
    throw Error(`unknown proyectType: ${proyectType}`)
  }

  return Object
    .entries(staffRoles)
    .map(([k, v]) => v)
    .filter(item => item.level >= staffRoles[role].level && item.projectTypes.includes(proyectType))
    .map(e => e.role)
}
