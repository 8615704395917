import { isObject } from 'lodash'

export default (user, entityType, role) => {
  const getIds = (obj, field, role) => Object.entries(obj ?? {})
    .flatMap(([key, val]) => key === field
      ? Object.entries(val).filter(([, { roles = [] }]) => roles.includes(role)).map(([id]) => id)
      : getIds(isObject(val) ? val : {}, field, role))

  return getIds(user, `by${entityType[0].toUpperCase()}${entityType.slice(1)}`, role)
}
