const getObjProperty = (obj, property) => property.split('.').reduce((acc, prop) => acc && acc[prop], obj)

export default {
  methods: {
    getErrorMessage(validation) {
      const relevantParams = [validation.params.min, validation.params.max].filter(v => v !== null && v !== undefined)
      return this.$t(`validation.${validation.name}`, relevantParams)
    },
    // Always returns String, containing the first encountered error message, if any, otherwise returns ''
    getFieldErrors(field) {
      const value = getObjProperty(this.$v.form, field)
      // date range fields are either { start: Date() } or { start: Date(), end: Date() }
      if ((value?.start || value?.end) && value.$dirty) {
        if (value.$error) return this.$t(`validation.${field}`)
        const isShortTerm = this.$store.getters['project/data'].type === 'short-term'
        // all short-term programmes MUST have a valid date range
        const dateRangeValidation = field === 'dateRange'
          && !value.$params.start
          && !value.$params.end
        return isShortTerm && dateRangeValidation ? this.$t(`validation.${field}`) : ''
      }
      if (value.$each) {
        // If the form field is an array, we call this same function upon each value and then return the first error found, if any
        const results = Object.keys(value.$each.$iter).map(i => this.getFieldErrors(`${field}.$each.$iter.${i}`))
        const firstError = results.find(result => result !== '')
        return firstError || ''
      }
      if (!value.$dirty) return '' // If the field has not been touched yet
      const validations = Object.keys(value.$params)
      const firstFailedValidation = validations.find(validation => !value[validation])
      return firstFailedValidation ? this.getErrorMessage({ name: firstFailedValidation, params: value.$params[firstFailedValidation] ?? {} }) : ''
    },
    isFormValid() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return false
      return true
    },
    copyToClipboard(copyText) {
      navigator.clipboard.writeText(copyText)
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', this.$t('common.copiedSuccess')])
    },
    // Dynamic Form Fields
    updateFormField(data) {
      this.$store.commit('dynamicForm/updateField', data ?? this.form)
    },
    async scrollToFirstError() {
      await new Promise(resolve => { this.$nextTick(() => resolve()) })
      const firstErrorField = document.querySelector('.error--text .v-messages__wrapper .v-messages__message')
      if (!firstErrorField) return
      firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
    validateKeyNumeric(e) {
      const pattern = /^[0-9]$/
      if (pattern.test(e.key)) return true
      e.preventDefault()
    },
    validateKeyNumericPrice(e) {
      const pattern = e.target.value.length ? /^[0-9]$/ : /^[1-9]$/
      if (pattern.test(e.key)) return true
      e.preventDefault()
    },
  },
}
