import rolesEnum from '@/enums/rolesEnum'
import projectTypeEnum from '@/enums/projectTypeEnum'

export default [
  {
    id: 'settings',
    name: 'project.sections.settings',
    icon: 'settings',
    conditions: 'all',
  },
  {
    id: 'globalSettings',
    name: 'project.sections.generalInformation',
    route: { name: 'project-edit' },
    parentId: 'settings',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'competitionStructure',
    name: 'competition.sections.structure',
    icon: 'camera',
    route: { name: 'competition-structure' },
    parentId: 'settings',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'competitionMatchLocations',
    name: 'competition.sections.matchLocations',
    route: { name: 'competition-matchLocations' },
    parentId: 'settings',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'categories',
    name: 'project.sections.categories',
    route: { name: 'project-categories-and-teams' },
    parentId: 'settings',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'sponsorization',
    name: 'project.sections.sponsorization',
    icon: 'camera',
    route: { name: 'project-sponsorization' },
    parentId: 'settings',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'enrollmentSection',
    name: 'project.sections.enrollmentsTitle',
    icon: 'playlist_add_check',
    conditions: 'all',
  },
  {
    id: 'form',
    name: 'project.sections.form',
    route: { name: 'project-form' },
    parentId: 'enrollmentSection',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'waiver',
    name: 'common.waiver',
    route: { name: 'project-waiver' },
    parentId: 'enrollmentSection',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'enrollments',
    name: 'project.sections.enrollments',
    route: { name: 'project-enrollments' },
    parentId: 'users',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'payments',
    name: 'project.sections.payments',
    icon: 'credit_card',
    route: { name: 'project-payments' },
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'emailAdmission',
    name: 'project.sections.emailAdmission',
    icon: 'send',
    route: { name: 'project-emailAdmission' },
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'users',
    name: 'project.sections.users',
    icon: 'person_outline',
    conditions: 'all',
  },
  {
    id: 'players',
    name: 'project.sections.players',
    route: { name: 'project-players' },
    parentId: 'users',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.COACH },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'staff',
    name: 'project.sections.staff',
    route: { name: 'project-staff' },
    parentId: 'users',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.COACH },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'fans',
    name: 'project.sections.fans',
    route: { name: 'project-fans' },
    parentId: 'users',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'activities',
    name: 'project.sections.activities',
    icon: 'sports',
    route: { name: 'project-calendar', params: { date: 'all' } },
    conditions: [
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'schedule',
    name: 'project.sections.schedule',
    icon: 'calendar_today',
    route: { name: 'calendar-schedule' },
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.COACH },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'news',
    name: 'project.sections.posts',
    icon: 'wysiwyg',
    route: { name: 'posts' },
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.COACH },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'eventLog',
    name: 'project.sections.eventLog',
    icon: 'schedule',
    route: { name: 'project-eventLog' },
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.SUBMANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'clubEnrollments',
    name: 'competition.sections.clubEnrollments',
    icon: 'format_list_bulleted',
    route: { name: 'competition-club-enrollments' },
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'clubs',
    name: 'competition.sections.clubs',
    icon: 'shield',
    route: { name: 'competition-clubs' },
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  ...(window.isDev ? [{
    id: 'groups',
    name: 'project.sections.groups',
    icon: 'person-group-outline',
    route: { name: 'competition-groups' },
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  }] : []),
  {
    id: 'matchesFilter',
    name: 'competition.sections.cohortsMatches',
    icon: 'sports_basketball',
    route: { name: 'competition-matches-filter' },
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'bans',
    name: 'competition.sections.bans',
    icon: 'shield',
    conditions: 'all',
  },
  {
    id: 'createBans',
    name: 'competition.sections.createBans',
    route: { name: 'competition-create-bans' },
    parentId: 'bans',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'manageBans',
    name: 'competition.sections.manageBans',
    route: { name: 'competition-manage-bans' },
    parentId: 'bans',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'rankingFilter',
    name: 'competition.sections.ranking',
    icon: 'emoji_events',
    route: { name: 'competition-ranking-filter' },
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'pushNotifications',
    name: 'organization.sections.pushNotifications',
    icon: 'add_alert',
    route: { name: 'project-pushNotifications' },
    conditions: 'all',
  },
  {
    id: 'publish',
    type: 'button',
    text: 'project.actions.publish',
    conditions: [
      { type: projectTypeEnum.ACADEMY, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.SHORT, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.LONG, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.GUILD, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'openCompetition',
    type: 'button',
    text: 'project.actions.openCompetition',
    conditions: [
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.MANAGER },
      { type: projectTypeEnum.COMPETITION, role: rolesEnum.SUBMANAGER },
    ],
  },
  {
    id: 'divider-3',
    type: 'divider',
    isStyle: true,
    conditions: 'all',
  },
]
