import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl, uploadFileAndGetObj, callBackend } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getModifiedFields from '../../utils/getModifiedFields'
import i18n from '@/plugins/i18n'
import projectRouteNames from '@/enums/projectRouteNames'

const getDbFields = async ({ organizationId, projectId, postRef, post, data, publish, rootGetters, dotNotation = true }) => {
  const banner = data.banner ? await uploadFileAndGetUrl(postRef.path, data.banner) : null
  const attachments = await Promise.all(data.attachments.map(att => uploadFileAndGetObj(postRef.path, att)))
  const modifiedFields = getModifiedFields(post, { ...data, banner, attachments, published: publish, active: true, id: postRef.id }, dotNotation)
  const newUserIds = Object.keys(modifiedFields).some(field => field.startsWith('userSources'))
    ? await callBackend('activities/get-user-ids', { organizationId, projectId, userSources: data.userSources })
    : null
  return {
    ...modifiedFields,
    updatedAt: FieldValue.serverTimestamp(),
    ...(!Object.keys(post).length && { createdAt: FieldValue.serverTimestamp(), createdBy: rootGetters['user/data'].id }),
    ...(!post.published && publish && { publishedAt: FieldValue.serverTimestamp() }),
    ...(!post.userIds && !newUserIds && { userIds: ['all'] }),
    ...(newUserIds && { userIds: newUserIds }),
  }
}

const doFirstTimePublishActions = async ({ organizationId, projectId, post, rootGetters }) => {
  await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ updatedAt: FieldValue.serverTimestamp() })
  // Send notifications
  if (!rootGetters['project/doNotSendNotificationOnActivityPublish']) {
    const context = { organizationId, projectId, postId: post.id }
    const data = {
      isCustom: true,
      title: i18n.t('pushNotifications.transactional.postPublished.title', [post.title]),
      body: i18n.t('pushNotifications.transactional.postPublished.body', [post.title]),
      linkType: 'postDetail',
    }
    if (post.userIds.includes('all')) callBackend('notifications/push/create', { scope: 'project', context, data })
    else callBackend('notifications/push/create-multiple', { userIds: post.userIds, context, data })
  }
  // Add user alerts
  callBackend('users/add-alerts', { userIds: post.userIds, type: 'academy' })
}

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbPosts: [],
  }),
  getters: {
    data: state => state.dbData,
    posts: state => state.dbPosts,
  },
  actions: {
    bindPosts: firestoreAction(({ bindFirestoreRef, rootGetters }, { organizationId, projectId }) => {
      const collectionRef = rootGetters['project/isUserSubmanager']
        ? db.collection(`properties/${organizationId}/projects/${projectId}/news`)
        : db.collection(`properties/${organizationId}/projects/${projectId}/news`).where('userIds', 'array-contains-any', [rootGetters['user/data'].id, 'all'])
      return bindFirestoreRef('dbPosts', collectionRef)
    }),
    unbindPosts: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPosts')),
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, postId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc(postId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    async create({ rootGetters }, { organizationId, projectId, data, publish = true }) {
      const postRef = db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc()
      const post = await getDbFields({ organizationId, projectId, postRef, post: {}, data, publish, rootGetters, dotNotation: false })
      await postRef.set(post)
      if (publish) await doFirstTimePublishActions({ organizationId, projectId, post, rootGetters })
      logEvent({ action: 'create', entityType: projectRouteNames.POST, entity: post })
      return postRef.id
    },
    async createClone({ getters }, { organizationId, projectId, data }) {
      const originalPost = getters.posts.find(post => post.id === data.id)
      const newPostRef = db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc()
      const newPost = {
        ...originalPost,
        id: newPostRef.id,
        name: `${originalPost.title} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newPostRef.set(newPost)
      logEvent({ action: 'create', entityType: projectRouteNames.POST, entity: newPost })
      return newPostRef.id
    },
    async update({ rootGetters }, { organizationId, projectId, post, data, publish = true }) {
      const postRef = db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc(post.id)
      const dataToUpdate = await getDbFields({ organizationId, projectId, postRef, post, data, publish, rootGetters })
      await postRef.update(dataToUpdate)
      if (publish) {
        if (post.title !== data.title) {
          logEvent({
            action: 'rename',
            entityType: projectRouteNames.POST,
            entity: post,
            newName: data.title,
          })
        }
      }
      logEvent({ action: 'update', entityType: projectRouteNames.POST, entity: post })
      if (publish) {
        if (!post.published) await doFirstTimePublishActions({ organizationId, projectId, post: { ...post, ...dataToUpdate }, rootGetters })
      }
      return true
    },
    async publish(context, { organizationId, projectId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc(data.id).update({ published: true, publishedAt: FieldValue.serverTimestamp() })
      logEvent({ action: 'publish', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async archive(context, { organizationId, projectId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc(data.id).update({ active: false })
      logEvent({ action: 'archive', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async unarchive(context, { organizationId, projectId, data }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/news`).doc(data.id).update({ active: true, published: false })
      logEvent({ action: 'unarchive', entityType: projectRouteNames.POST, entity: data })
      return true
    },
    async delete(context, { organizationId, projectId, data }) {
      const path = `properties/${organizationId}/projects/${projectId}/news/${data.id}`
      await callBackend('recursive-delete', { path })
      logEvent({ action: 'delete', entityType: projectRouteNames.POST, entity: data })
      return true
    },
  },
}
