import i18n from '@/plugins/i18n'
import store from '@/store'
import pushNotificationsRoutes from '@/modules/pushNotifications/clusterRouter'
import guildMatchesRouter from '@/modules/guildMatches/clusterRouter'

export default [
  {
    path: '/organization/:organizationId/cluster/:clusterId',
    name: 'cluster',
    component: () => import('@/modules/cluster/Cluster'),
    redirect: { name: 'cluster-projects' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, clusterId } = to.params
      store.commit('loader/show')

      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('organization/bind', organizationId)
        await store.dispatch('organization/bindProjects', organizationId)
        await store.dispatch('guildMatches/bindGuildMatches', organizationId)
      } else {
        if (!store.getters['guildMatches/guildMatches']?.length) await store.dispatch('guildMatches/bindGuildMatches', organizationId)
        if (!store.getters['organization/projects']?.length) await store.dispatch('organization/bindProjects', organizationId)
      }

      if (store.state.cluster.dbData?.id !== clusterId) {
        const cluster = await store.dispatch('cluster/bind', { organizationId, clusterId })
        if (!cluster) next({ name: '404' })
        await store.dispatch('cluster/bindManagers', { organizationId, clusterId })
        if (!store.state.organization.dbProjects?.length) await store.dispatch('cluster/bindProjects', { organizationId, clusterId })
        await store.dispatch('cluster/bindStats', { organizationId, clusterId })
      } else {
        if (!store.state.cluster.dbManagers?.length) await store.dispatch('cluster/bindManagers', { organizationId, clusterId })
        if (!store.getters['cluster/projects']?.length) await store.dispatch('cluster/bindProjects', { organizationId, clusterId })
      }
      store.commit('loader/hide')
      if (!(store.getters['cluster/isUserClusterCollaborator'] || store.getters['organization/isUserAdmin'])) next({ name: '404' })
      next()
    },
    children: [
      {
        path: 'projects',
        name: 'cluster-projects',
        component: () => import('@/modules/organization/ProjectList'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('guild.sections.guilds')} - OWQLO`,
          headerTitle: () => i18n.t('guild.sections.guilds'),
        },
        props: true,
      },
      {
        path: 'settings',
        name: 'cluster-edit',
        component: () => import('@/modules/cluster/EditCluster'),
        meta: {
          title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.settings'),
        },
        props: true,
      },
      {
        path: 'players',
        name: 'cluster-players',
        component: () => import('@/modules/cluster/PlayerStats'),
        meta: {
          title: () => `${store.getters['cluster/data'].name} - ${i18n.t(`project.sections.players-${store.getters['project/data']?.type}`)} - OWQLO`,
          headerTitle: () => i18n.t(`project.sections.players-${store.getters['cluster/data']?.type}`),
        },
        props: true,
      },
      {
        path: 'staff-list',
        name: 'cluster-staff-list',
        component: () => import('@/modules/cluster/StaffList'),
        meta: {
          title: () => `${store.getters['cluster/data'].name} - ${i18n.t('cluster.sections.managers')} - OWQLO`,
          headerTitle: () => i18n.t('cluster.sections.managers'),
        },
        props: true,
      },
      ...pushNotificationsRoutes,
      ...guildMatchesRouter,
    ],
  },
]
