<template>
  <div class="d-flex align-center">
    <v-icon v-if="text" color="primary" size="18" class="mr-1" @click.stop="copyToClipboard(text)" v-text="'content_copy'" />
    <v-tooltip top color="white">
      <template #activator="{ on, attrs }">
        <span class="d-inline text-truncate" style="max-width: 6rem;" v-bind="attrs" v-on="on" v-text="text" />
      </template>
      <div class="tooltip-text pa-2" v-text="text" />
    </v-tooltip>
  </div>
</template>

<script>
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'CopyText',
  mixins: [formUtils],
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
