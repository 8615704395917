import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: '/organization/:organizationId/project/:projectId/club/:clubId',
    name: 'club',
    component: () => import('@/modules/club/Club'),
    redirect: { name: 'club-edit' },
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, clubId } = to.params
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('organization/bind', organizationId)
      }
      if (store.state.project.dbData?.id !== projectId) {
        await store.dispatch('project/bind', { organizationId, projectId })
        await store.dispatch('project/bindServices', { organizationId, projectId })
      }
      if (store.state.club.dbData?.id !== clubId) {
        const entity = await store.dispatch('club/bind', { organizationId, projectId, clubId })
        if (!entity) next({ name: '404' })
        await store.dispatch('club/bindTeams', { clubId, projectId })
      }
      if (!store.state.club.dbTeams.length) await store.dispatch('club/bindTeams', { clubId, projectId })
      store.commit('loader/hide')

      // Security
      if (!store.state.organization.dbData) await store.dispatch('organization/bind', organizationId)
      if (!store.state.project.dbData) await store.dispatch('project/bind', { organizationId, projectId })
      if (!store.state.club.dbData) await store.dispatch('club/bind', { organizationId, projectId, clubId })
      const isUserOrganizationAdmin = store.getters['user/adminOrganizationIds'].includes(to.params.organizationId)
      const isUserProjectCollab = store.getters['user/collabProjectIds'].includes(to.params.projectId)
      const isUserclubCollab = store.getters['user/collabClubIds'].includes(to.params.clubId)
      if (!(isUserOrganizationAdmin || isUserProjectCollab || isUserclubCollab || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      else next()
    },
    children: [
      {
        path: 'settings',
        name: 'club-edit',
        component: () => import('@/modules/club/EditClub'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('club.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.settings'),
        },
        props: true,
      },
      {
        path: 'cohort/:cohortId/team/:teamId',
        name: 'club-team',
        component: () => import('@/modules/club/Team'),
        meta: {
          title: () => `${store.getters['club/data']?.name} - ${i18n.t('club.sections.team')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.teamDetail'),
        },
        props: true,
      },
      {
        path: 'trips/:serviceId',
        name: 'club-tripService',
        component: () => import('@/modules/club/TripService'),
        meta: {
          title: () => `${i18n.t('club.sections.trips')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.trips'),
        },
        props: true,
      },
      {
        path: 'log',
        name: 'club-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.getters['club/data'].name} - ${i18n.t('club.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('club.sections.eventLog'),
        },
        props: true,
      },
    ],
  },
]
