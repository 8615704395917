import rolesEnum from '@/enums/rolesEnum'

export default [
  {
    id: 'globalSettings',
    name: 'project.sections.generalInformation',
    icon: 'settings',
    route: { name: 'cluster-edit' },
    conditions: [
      { role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'projects',
    name: 'cluster.sections.guilds',
    icon: 'folder_open',
    route: { name: 'cluster-projects' },
    conditions: 'all',
  },
  ...(window.isDev ? [{
    id: 'tickets',
    name: 'organization.sections.tickets',
    icon: 'confirmation_number',
    route: { name: 'cluster-tickets' },
    conditions: [
      { role: rolesEnum.MANAGER },
    ],
  }] : []),
  {
    id: 'users',
    name: 'project.sections.users',
    icon: 'person_outline',
    conditions: 'all',
  },
  {
    id: 'players',
    name: 'project.sections.players-guild',
    route: { name: 'cluster-players' },
    parentId: 'users',
    conditions: [
      { role: rolesEnum.MANAGER },
    ],
  },
  {
    id: 'staffList',
    name: 'cluster.sections.managers',
    route: { name: 'cluster-staff-list' },
    parentId: 'users',
    conditions: 'all',
  },
  {
    id: 'pushNotifications',
    name: 'organization.sections.pushNotifications',
    icon: 'add_alert',
    route: { name: 'cluster-pushNotifications' },
    conditions: 'all',
  },
  {
    id: 'publish',
    type: 'button',
    text: 'project.actions.publish',
    conditions: [
      { role: rolesEnum.MANAGER },
    ],
  },
]
