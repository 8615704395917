import rolesEnum from '@/enums/rolesEnum'

export default [
  {
    id: 'settings',
    name: 'organization.sections.settings',
    icon: 'settings',
    conditions: 'all',
  },
  {
    id: 'globalSettings',
    name: 'organization.sections.globalSettings',
    route: { name: 'organization-settings' },
    parentId: 'settings',
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  },
  {
    id: 'terms',
    name: 'organization.sections.terms',
    route: { name: 'organization-terms' },
    parentId: 'settings',
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  },
  {
    id: 'projects',
    name: 'organization.sections.projects',
    icon: 'folder_open',
    route: { name: 'organization-projects' },
    conditions: 'all',
  },
  ...(window.isDev ? [{
    id: 'tickets',
    name: 'organization.sections.tickets',
    icon: 'confirmation_number',
    route: { name: 'organization-tickets' },
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  }] : []),
  {
    id: 'peopleAdmins',
    name: 'organization.sections.peopleAdmins',
    icon: 'person_outline',
    route: { name: 'organization-staff-list', params: { typeId: 'admin' } },
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  },
  {
    id: 'stats',
    name: 'organization.sections.stats',
    icon: 'insert_chart_outlined',
    route: { name: 'organization-statistics' },
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  },
  {
    id: 'pushNotifications',
    name: 'organization.sections.pushNotifications',
    icon: 'add_alert',
    route: { name: 'organization-pushNotifications' },
    conditions: 'all',
  },
  {
    id: 'eventLog',
    name: 'organization.sections.eventLog',
    icon: 'schedule',
    route: { name: 'organization-eventLog' },
    conditions: [
      { role: rolesEnum.ADMIN },
    ],
  },
  {
    id: 'divider-3',
    type: 'divider',
    isStyle: true,
    conditions: 'all',
  },
]
