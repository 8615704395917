import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend, uploadFileAndGetObj, runCloudFunction } from '@/services/firebase'
import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: () => ({
    dbGuildMatches: [],
    dbActivities: [],
    dbTicketEnrollments: [],
  }),

  getters: {
    guildMatches: state => state.dbGuildMatches,
    activities: state => state.dbActivities,
    ticketEnrollments: state => state.dbTicketEnrollments,
  },

  actions: {
    // read
    bindGuildMatches: firestoreAction(({ bindFirestoreRef }, organizationId) => bindFirestoreRef('dbGuildMatches', db.collection(`properties/${organizationId}/guildMatches`))),
    bindActivities: firestoreAction(({ bindFirestoreRef }, { organizationId, guildMatchId }) => bindFirestoreRef(
      'dbActivities',
      db.collectionGroup('activities').where('organizationId', '==', organizationId).where('guildMatchId', '==', guildMatchId),
    )),
    bindTicketEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbTicketEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).where('activityId', '==', activityId),
    )),

    async assignTicketsToGuild({ rootState }, { organizationId, project, cluster, guildMatchId, ticketCount }) {
      await callBackend('projects/guild/assign-tickets-to-guild', { organizationId, projectId: project.id, guildMatchId, ticketCount })

      const managers = rootState.cluster?.dbManagers
      for (const manager of managers) {
        const payload = {
          templateId: 'd-eed268dbbe294800ab67dab01ffe3bbf',
          email: manager.email,
          dynamicTemplateData: { clusterName: cluster.name, guildName: project.name, ticketCount, lang: rootState.locale },
        }
        runCloudFunction('sendDynamicTemplateEmail', payload)
      }

      await Promise.all(managers.map(async manager => {
        const context = { organizationId, userId: manager.id, clusterId: cluster.id, projectId: project.id }
        const data = {
          isCustom: true,
          title: i18n.t('pushNotifications.transactional.guildAssignTicket.title', [ticketCount]),
          body: i18n.t('pushNotifications.transactional.guildAssignTicket.body', [cluster.name, project.name]),
          linkType: 'projectDetail',
        }
        return callBackend('notifications/push/create', { scope: 'user', context, data })
      }))
    },

    async readUserTicketEnrollments(context, { organizationId, projectId, userId }) {
      const snapTicketEnrollments = await db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).where('userId', '==', userId).get()
      return snapTicketEnrollments.docs.map(snap => snap.data())
    },

    // update
    async enableAndPublish({ rootState }, { organizationId, guildMatch, cluster, data, ticketCount }) {
      const dataToUpdate = { ...data, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/guildMatches`).doc(guildMatch.id).update(dataToUpdate)

      const managers = rootState.cluster?.dbManagers
      for (const manager of managers) {
        const payload = {
          templateId: 'd-0f603cd4a57a494e9302f29d5ef5390e',
          email: manager.email,
          dynamicTemplateData: { clusterName: cluster.name, matchName: guildMatch.name, ticketCount, lang: rootState.locale },
        }
        runCloudFunction('sendDynamicTemplateEmail', payload)
      }

      await Promise.all(managers.map(async manager => {
        const context = { organizationId, userId: manager.id, clusterId: cluster.id }
        const dataPushNotification = {
          isCustom: true,
          title: i18n.t('pushNotifications.transactional.guildMatchUpdateAndEnableTicketCount.title', [ticketCount]),
          body: i18n.t('pushNotifications.transactional.guildMatchUpdateAndEnableTicketCount.body', [cluster.name, guildMatch.name]),
          linkType: 'clusterDetail',
        }
        return callBackend('notifications/push/create', { scope: 'user', context, data: dataPushNotification })
      }))

      return true
    },

    async updateActivity({ state }, { organizationId, projectId, activityId, data }) {
      const ref = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId)
      const attachments = data.attachments ? await Promise.all(data.attachments.map(file => uploadFileAndGetObj(ref.path, file))) : []
      await ref.update({ ...data, attachments })
      return true
    },

    async approveTicketEnrollment({ rootState }, { organizationId, projectId, id, purchaseId, data, email, activity, userId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).doc(id).update(data)
      await db.collection('purchases').doc(purchaseId).update({ paymentStatus: 'requested' })

      const payload = {
        templateId: 'd-3627ca8a885943839bd907f40203ed39',
        email,
        dynamicTemplateData: { projectName: rootState.project.dbData.name, matchName: activity.name, lang: rootState.project.dbData.language },
      }
      runCloudFunction('sendDynamicTemplateEmail', payload)

      const context = { organizationId, projectId, activityId: activity.id, userId }
      const dataPushNotification = {
        isCustom: true,
        title: i18n.t('pushNotifications.transactional.guildTicketAccepted.title', [activity.name]),
        body: i18n.t('pushNotifications.transactional.guildTicketAccepted.body', [activity.name]),
        linkType: 'pendingPayments',
      }
      callBackend('notifications/push/create', { scope: 'user', context, data: dataPushNotification })
    },

    async rejectTicketEnrollment({ rootState }, { organizationId, projectId, id, purchaseId, data, email, activity, userId }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/ticketEnrollments`).doc(id).update(data)
      await db.collection('purchases').doc(purchaseId).update({ paymentStatus: 'notRequested' })

      const payload = {
        templateId: 'd-256d49f4cb5b4ed68cbb35789cecd52a',
        email,
        dynamicTemplateData: { projectName: rootState.project.dbData.name, matchName: activity.name, lang: rootState.project.dbData.language },
      }
      runCloudFunction('sendDynamicTemplateEmail', payload)

      const context = { organizationId, projectId, activityId: activity.id, userId }
      const dataPushNotification = {
        isCustom: true,
        title: i18n.t('pushNotifications.transactional.guildTicketRejected.title', [activity.name]),
        body: i18n.t('pushNotifications.transactional.guildTicketRejected.body', [activity.name]),
        linkType: 'activityDetail',
      }
      callBackend('notifications/push/create', { scope: 'user', context, data: dataPushNotification })
    },
  },
}
