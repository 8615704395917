import { db, FieldValue, runCloudFunction, callBackend } from '@/services/firebase'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import rolesEnum from '@/enums/rolesEnum'

export const subscribeToEntity = async ({ userId, entity, lang }) => {
  const { type, role, organizationId, projectId, teamId } = entity
  const userRef = db.collection('users').doc(userId)
  const user = (await userRef.get()).data()
  if (type === 'organization') {
    const rolesField = `roles.byProperty.${organizationId}.roles`
    await userRef.update({ [rolesField]: FieldValue.arrayUnion(role, 'subscriber') })
  } else if (type === 'project') {
    const rolesField = `roles.byProperty.${organizationId}.byProject.${projectId}.roles`
    await userRef.update({ [rolesField]: FieldValue.arrayUnion(role, 'subscriber') })
    const platform = role === rolesEnum.MATCHEDITOR ? 'app' : 'web'
    runCloudFunction('sendUserJoinedEmail', {
      email: user.email,
      name: user.firstName,
      role,
      isNewUser: false,
      platform,
      link: await getEmailLoginLink({ isNewUser: false, platform }),
      lang,
      organizationId,
      projectId,
    })
  } else if (type === 'cluster') {
    const { clusterId } = entity
    const rolesField = `roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`
    await userRef.update({ [rolesField]: FieldValue.arrayUnion(role, 'subscriber') })
    const platform = role === rolesEnum.MATCHEDITOR ? 'app' : 'web'
    runCloudFunction('sendUserJoinedEmail', {
      email: user.email,
      name: user.firstName,
      role,
      isNewUser: false,
      platform,
      link: await getEmailLoginLink({ isNewUser: false, platform }),
      lang,
      organizationId,
      clusterId,
    })
  } else if (type === 'team') {
    callBackend('teams/users/subscribe', { organizationId, projectId, userId, teamId, role })
  }
}

export const unsubscribeFromEntity = async ({ userId, entity }) => {
  const { type, role, organizationId, projectId, teamId } = entity
  const userRef = db.collection('users').doc(userId)
  if (type === 'organization') {
    const rolesField = `roles.byProperty.${organizationId}.roles`
    await userRef.update({ [rolesField]: FieldValue.arrayRemove(role) })
    // const userRolesByProject = roles.byProperty[organizationId].byProject
    // const userProjects = state.dbProjects.filter(project => userRolesByProject[project.id]?.roles?.includes('collaborator'))
    // for (const project of userProjects) {
    //   await dispatch('project/removeCollaborator', { organizationId, project, user }, { root: true })
    // }
  } else if (type === 'project') {
    const rolesField = `roles.byProperty.${organizationId}.byProject.${projectId}.roles`
    const teamField = `roles.byProperty.${organizationId}.byProject.${projectId}.byTeam`
    await userRef.update({
      [rolesField]: FieldValue.arrayRemove(role),
      [teamField]: FieldValue.delete(),
    })
  } else if (type === 'cluster') {
    const { clusterId } = entity
    const rolesField = `roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`
    await userRef.update({
      [rolesField]: FieldValue.arrayRemove(role),
    })
  } else if (type === 'team') {
    callBackend('teams/users/unsubscribe', { organizationId, projectId, userId, teamId, role })
  }
}
