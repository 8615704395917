import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'structure',
    name: 'competition-structure',
    component: () => import('@/modules/competition/structure/CompetitionStructure'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.structure')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.structure'),
    },
    props: true,
  },
  {
    path: 'match-locations',
    name: 'competition-matchLocations',
    component: () => import('@/modules/competition/MatchLocations'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchLocations')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchLocations'),
    },
    props: true,
  },
  {
    path: 'club-enrollments',
    name: 'competition-club-enrollments',
    component: () => import('@/modules/competition/ClubEnrollmentList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.clubEnrollments')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.clubEnrollments'),
    },
    props: true,
  },
  {
    path: 'groups',
    name: 'competition-groups',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.groups')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.groups'),
    },
    props: route => ({ ...route.params, type: 'groups' }),
  },
  {
    path: 'groups/create-cohort-groups/:cohortId',
    name: 'competition-create-cohort-groups',
    component: () => import('@/modules/competition/groups/CreateCohortGroups'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.groups')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.groups'),
    },
    props: true,
  },
  {
    path: 'clubs',
    name: 'competition-clubs',
    component: () => import('@/modules/competition/ClubList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.clubs')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.clubs'),
    },
    props: true,
  },
  {
    path: 'matches',
    name: 'competition-matches-filter',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.cohortsMatches')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.cohortsMatches'),
    },
    props: route => ({ ...route.params, type: 'matches' }),
  },
  {
    path: 'matches/by-cohort/:cohortId',
    name: 'competition-matches-by-cohort',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByCohort')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByCohort'),
    },
    props: true,
  },
  {
    path: 'matches/by-club/:clubId',
    name: 'competition-matches-by-club',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByClub')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByClub'),
    },
    props: true,
  },
  {
    path: 'matches/by-location/:locationId',
    name: 'competition-matches-by-location',
    component: () => import('@/modules/competition/matches/MatchList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.matchesByLocation')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.matchesByLocation'),
    },
    props: true,
  },
  {
    path: 'matches/by-cohort/:cohortId/:matchId/edit',
    name: 'match-edit',
    component: () => import('@/modules/competition/matches/EditMatch'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('common.match')} - OWQLO`,
      headerTitle: () => i18n.t('common.match'),
    },
    props: true,
  },
  {
    path: 'ranking',
    name: 'competition-ranking-filter',
    component: () => import('@/modules/competition/FilterList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.ranking')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.ranking'),
    },
    props: route => ({ ...route.params, type: 'ranking' }),
  },
  {
    path: 'ranking/by-cohort/:cohortId',
    name: 'competition-ranking-by-cohort',
    component: () => import('@/modules/competition/ranking/RankingList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.ranking')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.ranking'),
    },
    props: true,
  },
  {
    path: 'create-bans',
    name: 'competition-create-bans',
    component: () => import('@/modules/competition/bans/CreateBans'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.createBans')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.createBans'),
    },
    props: true,
  },
  {
    path: 'manage-bans',
    name: 'competition-manage-bans',
    component: () => import('@/modules/competition/bans/ManageBans'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('competition.sections.manageBans')} - OWQLO`,
      headerTitle: () => i18n.t('competition.sections.manageBans'),
    },
    props: true,
  },
]
