import { firestoreAction } from 'vuexfire'
import i18n from '@/plugins/i18n'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj, deleteFileAndReturnSuccess, FieldValue, callBackend } from '@/services/firebase'
import { getI18n } from '@/services/deepl'
import logEvent from '@/utils/logEvent'
import getModifiedFields from '../../utils/getModifiedFields'

const getDbFields = async ({ organizationId, projectId, activityRef, activity, data, publish, rootGetters, dotNotation = true }) => {
  const banner = data.banner ? await uploadFileAndGetUrl(activityRef.path, data.banner) : null
  const attachments = await Promise.all(data.attachments.map(att => uploadFileAndGetObj(activityRef.path, att)))
  const modifiedFields = getModifiedFields(activity, { ...data, banner, attachments, published: publish, active: true, id: activityRef.id }, dotNotation)
  const newUserIds = Object.keys(modifiedFields).some(field => field.startsWith('userSources'))
    ? await callBackend('activities/get-user-ids', { organizationId, projectId, userSources: data.userSources })
    : null
  return {
    ...modifiedFields,
    updatedAt: FieldValue.serverTimestamp(),
    ...(!Object.keys(activity).length && { createdAt: FieldValue.serverTimestamp() }),
    ...(!activity.published && publish && { publishedAt: FieldValue.serverTimestamp() }),
    ...(!activity.userIds && !newUserIds && { userIds: ['all'] }),
    ...(newUserIds && { userIds: newUserIds }),
    ...(rootGetters['project/needsTranslation'] && publish && {
      needsTranslation: true, // TODO: eliminar cuando en apps se refactorice
      i18n: await getI18n(rootGetters['project/language'], {}, data, ['description']), // TODO make this funcion take only modified fields and return its result flattened
    }),
  }
}

const doUpdateActions = async ({ organizationId, projectId, activity, rootGetters }) => {
  await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ updatedAt: FieldValue.serverTimestamp() })
  if (!rootGetters['project/doNotSendNotificationOnActivityPublish']) {
    const context = { organizationId, projectId, activityId: activity.id }
    const data = {
      isCustom: true,
      title: i18n.t('pushNotifications.transactional.activityUpdated.title', [activity.name]),
      body: i18n.t('pushNotifications.transactional.activityUpdated.body', [activity.name]),
      linkType: 'activityDetail',
    }
    if (activity.userIds.includes('all')) callBackend('notifications/push/create', { scope: 'project', context, data })
    else callBackend('notifications/push/create-multiple', { userIds: activity.userIds, context, data })
  }
}

const doFirstTimePublishActions = async ({ organizationId, projectId, activity, rootGetters }) => {
  await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ updatedAt: FieldValue.serverTimestamp() })
  // Send notifications
  if (!rootGetters['project/doNotSendNotificationOnActivityPublish']) {
    const context = { organizationId, projectId, activityId: activity.id }
    const data = {
      isCustom: true,
      title: i18n.t('pushNotifications.transactional.activityPublished.title', [activity.name]),
      body: i18n.t('pushNotifications.transactional.activityPublished.body', [activity.name]),
      linkType: 'activityDetail',
    }
    if (activity.userIds.includes('all')) callBackend('notifications/push/create', { scope: 'project', context, data })
    else callBackend('notifications/push/create-multiple', { userIds: activity.userIds, context, data })
  }
  // Add user alerts
  callBackend('users/add-alerts', { userIds: activity.userIds, type: 'academy' })
}

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbComments: null,
  }),
  getters: {
    data: state => state.dbData,
    comments: state => state.dbComments,
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    bindComments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbComments',
      db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).orderBy('updatedAt', 'desc'),
    )),

    // Create
    async create({ rootState, rootGetters }, { organizationId, projectId, data, publish = true }) {
      console.log(publish)
      const activityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc()
      const activity = await getDbFields({ organizationId, projectId, activityRef, activity: {}, data, publish, rootGetters, dotNotation: false })
      if (activity.periodicity?.isPeriodic) {
        const periodicitySettings = { interval: activity.periodicity.interval, endDate: rootState.project.dbData.date.end.toDate() }
        await callBackend('activities/create-periodic-group', { organizationId, projectId, originalActivity: activity, periodicitySettings })
      } else await activityRef.set(activity)
      if (publish) await doFirstTimePublishActions({ organizationId, projectId, activity, rootGetters })
      logEvent({ action: 'create', entityType: 'activity', entity: activity })
      return activityRef.id
    },
    async createClone({ rootState }, { organizationId, projectId, activityId }) {
      const originalActivity = rootState.project.dbActivities.find(activity => activity.id === activityId)
      const newActivityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc()
      const newActivity = {
        ...originalActivity,
        id: newActivityRef.id,
        name: `${originalActivity.name} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newActivityRef.set(newActivity)
      logEvent({ action: 'create', entityType: 'activity', entity: newActivity })
      return newActivityRef.id
    },

    // Update
    async update(
      { rootState, rootGetters },
      { organizationId, projectId, activity, data, publish = true, targetPeriodicGroup = false, targetPastPeriodicActivities = false },
    ) {
      const activityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id)
      const dataToUpdate = await getDbFields({ organizationId, projectId, activityRef, activity, data, publish, rootGetters })
      if (targetPeriodicGroup) {
        await callBackend('activities/edit-periodic-group', {
          organizationId,
          projectId,
          groupId: activity.periodicity.groupId,
          data: (({ 'date.start': dateStart, 'date.end': dateEnd, userIds, id, ...rest }) => rest)(dataToUpdate),
          fromDate: targetPastPeriodicActivities ? rootState.project.dbData.date.start.toDate() : activity.date.start.toDate(),
          startDateChange: data.date.start.getTime() - activity.date.start.seconds * 1000,
          endDateChange: data.date.end.getTime() - activity.date.end.seconds * 1000,
          ...(dataToUpdate.userIds && {
            userIdsToAdd: dataToUpdate.userIds.filter(id => !activity.userIds.includes(id)),
            userIdsToRemove: activity.userIds.filter(id => !dataToUpdate.userIds.includes(id)),
          }),
        })
      } else await activityRef.update(dataToUpdate)
      if (publish) {
        if (!activity.published) await doFirstTimePublishActions({ organizationId, projectId, activity: { ...activity, ...dataToUpdate }, rootGetters })
        else await doUpdateActions({ organizationId, projectId, activity: { ...activity, ...dataToUpdate }, rootGetters })
        logEvent({ action: 'update', entityType: 'activity', entity: activity })
        if (activity.name !== data.name) logEvent({ action: 'rename', entityType: 'activity', entity: activity, newName: data.name })
        // If it is a streaming and is not started and more than 1 hour reamins until it starts, reschedule streaming link emails
        if (activity.type === 'streaming' && dataToUpdate.date && dataToUpdate.date.getTime() > (Date.now() + 60 * 60 * 1000)) {
          if (activity.emailTaskId) await callBackend('task-scheduler/cancel-task', { id: activity.emailTaskId })
          const emailTaskId = await callBackend('task-scheduler/create-task', {
            endpoint: 'activities/send-streaming-link-emails',
            payload: { organizationId, projectId, activityId: activity.id },
            dateInMs: dataToUpdate.date.getTime() - 60 * 60 * 1000,
          })
          activityRef.update({ emailTaskId })
        }
      }
      return true
    },
    async updateScore(context, { organizationId, projectId, eventId, teamsByIndex }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(eventId).update({ teamsByIndex })
    },
    async archive(context, { organizationId, projectId, activity }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id).update({ active: false })
      logEvent({ action: 'archive', entityType: 'activity', entity: activity })
      return true
    },
    async unarchive(context, { organizationId, projectId, activity }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id).update({ active: true })
      logEvent({ action: 'unarchive', entityType: 'activity', entity: activity })
      return true
    },

    // Delete
    async delete(context, { organizationId, projectId, activity, targetPeriodicGroup }) {
      if (targetPeriodicGroup) {
        await callBackend('activities/delete-periodic-group', {
          organizationId, projectId, groupId: activity.periodicity.groupId, fromDate: activity.date.start.toDate(),
        })
      } else await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/activities/${activity.id}` })
      if (activity.emailTaskId) await callBackend('task-scheduler/cancel-task', { id: activity.emailTaskId })
      logEvent({ action: 'delete', entityType: 'activity', entity: activity })
      return true
    },
    async deleteAttachment(context, { organizationId, projectId, activityId, attachment }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId).update({
        attachments: FieldValue.arrayRemove(attachment),
      })
      await deleteFileAndReturnSuccess(attachment.publicUrl)
    },
  },
}
