import i18n from '@/plugins/i18n'

export default [
  {
    path: '/organization/:organizationId/project/:projectId/club-enrollment-form/intro',
    name: 'club-enrollment-form-intro',
    component: () => import('@/modules/clubEnrollmentForm/IntroView'),
    props: true,
  },
  {
    path: '/organization/:organizationId/project/:projectId/club-enrollment-form',
    name: 'club-enrollment-form',
    component: () => import('@/modules/clubEnrollmentForm/MainView'),
    props: true,
    meta: {
      title: () => `${i18n.t('clubEnrollmentForm.title')} - OWQLO`,
    },
    redirect: { name: 'club-enrollment-form-intro' },
    children: [
      {
        path: 'step-1',
        name: 'club-enrollment-form-step-1',
        component: () => import('@/modules/clubEnrollmentForm/steps/1ClubData'),
        meta: {
          stepId: '1',
          title: () => `${i18n.t('clubEnrollmentForm.title')} - OWQLO`,
        },
        props: true,
      },
      {
        path: 'step-2',
        name: 'club-enrollment-form-step-2',
        component: () => import('@/modules/clubEnrollmentForm/steps/2AddTeams'),
        meta: {
          stepId: '2',
          title: () => `${i18n.t('clubEnrollmentForm.title')} - OWQLO`,
        },
        props: true,
      },
      {
        path: 'step-3',
        name: 'club-enrollment-form-step-3',
        component: () => import('@/modules/clubEnrollmentForm/steps/3AddStaff'),
        meta: {
          stepId: '3',
          title: () => `${i18n.t('clubEnrollmentForm.title')} - OWQLO`,
        },
        props: true,
      },
      {
        path: 'step-4',
        name: 'club-enrollment-form-step-4',
        component: () => import('@/modules/clubEnrollmentForm/steps/4Summary'),
        meta: {
          stepId: '4',
          title: () => `${i18n.t('clubEnrollmentForm.title')} - OWQLO`,
        },
        props: true,
      },
    ],
  },
]
