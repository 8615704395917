import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'players',
    name: 'project-players',
    component: () => import('@/modules/projectUsers/PlayerList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t(`project.sections.players-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.sections.players-${store.getters['project/data']?.type}`),
    },
    props: true,
  },
  {
    path: 'players/:playerId',
    name: 'project-player',
    component: () => import('@/modules/projectUsers/Player'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.academy.users.playerDetail')} - OWQLO`,
      headerTitle: () => i18n.t('project.academy.users.playerDetail'),
    },
    props: true,
  },
  {
    path: 'staff',
    name: 'project-staff',
    component: () => import('@/modules/projectUsers/StaffList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.staff')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.staff'),
    },
    props: true,
  },
  // {
  //   path: 'staff/:staffId',
  //   name: 'academy-staff',
  //   component: () => import('@/modules/academy/Staff'),
  //   meta: {
  //     title: () => `${store.getters['project/data']?.name} - ${i18n.t('academy.sections.staff')} - OWQLO`,
  //     headerTitle: () => i18n.t('academy.sections.staffDetail'),
  //   },
  //   props: true,
  // },
  {
    path: 'fans',
    name: 'project-fans',
    component: () => import('@/modules/projectUsers/FanList'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.fans')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.fans'),
    },
    props: true,
  },
]
