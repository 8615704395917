import { firestoreAction } from 'vuexfire'
import { db, auth, FieldValue, runCloudFunction, callBackend, uploadFileAndGetUrl, uploadFileAndGetObj } from '@/services/firebase'
import getUserEntityIds from '@/utils/getUserEntityIds'
import { subscribeToEntity } from '@/utils/entitySubscriptions'
import rolesEnum from '@/enums/rolesEnum'
import imagesExtensionsEnum from '@/enums/imagesExtensionsEnum'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbProjectMembership: null,
    dbOrganizationMembership: null,
  }),
  getters: {
    data: state => state.dbData,
    membership: state => ({ ...state.dbProjectMembership, organization: state.dbOrganizationMembership }),
    isAdmin: state => state.dbData.roles.roles?.includes('superadmin'),
    adminOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.ADMIN),
    collabOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.COLLABORATOR),
    collabProjectIds: (state, getters) => [...new Set([...getUserEntityIds(state.dbData, 'project', rolesEnum.COLLABORATOR), ...getters.coachProjectIds, ...getters.managerProjectIds, ...getters.submanagerProjectIds])],
    collabProjects: (state, getters, rootState, rootGetters) => (rootGetters['organization/projects'].length
      ? rootGetters['organization/projects']
      : rootGetters['cluster/projects'])
      .filter(p => getters.collabProjectIds.includes(p.id)),
    collabClusterIds: (state, getters) => [...new Set(getters.collabProjects.filter(p => p.clusterId).map(p => p.clusterId))],
    managerProjectIds: state => getUserEntityIds(state.dbData, 'project', rolesEnum.MANAGER),
    managerClusterIds: state => getUserEntityIds(state.dbData, 'cluster', rolesEnum.MANAGER),
    submanagerProjectIds: state => getUserEntityIds(state.dbData, 'project', rolesEnum.SUBMANAGER),
    coachProjectIds: state => getUserEntityIds(state.dbData, 'project', rolesEnum.COACH),
    coachTeamIds: state => getUserEntityIds(state.dbData, 'team', rolesEnum.COACH),
    playerTeamIds: state => getUserEntityIds(state.dbData, 'team', rolesEnum.PLAYER),
    collabClubs: state => Object.entries(state.dbData.roles.byProperty ?? {})
      .flatMap(([organizationId, { byProject = {} }]) => Object.entries(byProject)
        .flatMap(([projectId, { byClub = {} }]) => Object.entries(byClub)
          .filter(([, { roles = [] }]) => roles.includes('collaborator'))
          .map(([clubId]) => ({ organizationId, projectId, id: clubId })))),
    collabClubIds: (state, getters) => getters.collabClubs.map(club => club.id),
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbData', db.collection('users').doc(id))),
    bindProjectMembership: firestoreAction(({ bindFirestoreRef }, { id, projectId, role }) => bindFirestoreRef(
      'dbProjectMembership',
      db.collection(`users/${id}/membership`).doc(`${role}-project-${projectId}`),
    )),
    bindOrganizationMembership: firestoreAction(({ bindFirestoreRef }, { id, organizationId, role }) => bindFirestoreRef(
      'dbOrganizationMembership',
      db.collection(`users/${id}/membership`).doc(`${role}-organization-${organizationId}`),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    async read(context, id) { return (await db.collection('users').doc(id).get()).data() },
    async readByEmail(context, email) {
      if (!email) return null
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      return user ?? null
    },
    async readMembershipInfo(context, { id, entityType, entityId, role }) {
      return (await db.collection(`users/${id}/membership`).doc(`${role}-${entityType}-${entityId}`).get()).data()
    },
    async readPurchase(context, { purchaseId }) {
      return (await db.collection('purchases').doc(purchaseId).get()).data()
    },

    // Create
    async create({ rootState }, { email, password, secretKey, data }) {
      const lang = rootState.locale
      const invitation = (await db.collection('invitations').doc(email).get()).data()
      if (invitation.secretKey !== secretKey) throw new Error('Invalid secretKey')
      const { user: { uid } } = await auth.createUserWithEmailAndPassword(email, password)
      await db.collection('users').doc(uid).set({
        ...data,
        email,
        id: uid,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      if (invitation.entitiesToSubscribe?.length) {
        await Promise.all(invitation.entitiesToSubscribe.map(entity => subscribeToEntity({ userId: uid, entity, lang })))
      }
      return db.collection('invitations').doc(email).delete()
    },

    async createWithBackend(context, { data, email }) {
      const { id } = await callBackend('users/create-programatically', { data, email })
      return id
    },
    // Update
    update(context, { id, data }) {
      return db.collection('users').doc(id).update({
        ...data,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    async updateMembership(context, { id, role, entityType, entityId, data }) {
      const ref = db.collection(`users/${id}/membership`).doc(`${role}-${entityType}-${entityId}`)
      const attachments = data.attachments ? await Promise.all(data.attachments.map(file => uploadFileAndGetObj(ref.path, file))) : []

      const storagePath = `users/${id}/membership/subscriber-project-${entityId}/`
      if (data.form) {
        data.form = Object.fromEntries(await Promise.all(Object.entries(data.form)
          .map(async ([key, value]) => {
            if (typeof value === 'object' && value instanceof File) {
              return imagesExtensionsEnum.includes(value.name?.split('.').pop())
                ? [key, await uploadFileAndGetUrl(storagePath, value)]
                : [key, await uploadFileAndGetObj(storagePath, value)]
            }
            return [key, value]
          })))
      }

      await ref.update({ ...data, attachments })
    },
    addFavoriteOrganization(context, { id, organizationId }) {
      return db.collection('users').doc(id).update({ 'favorites.organizations': FieldValue.arrayUnion(organizationId) })
    },
    removeFavoriteOrganization(context, { id, organizationId }) {
      return db.collection('users').doc(id).update({ 'favorites.organizations': FieldValue.arrayRemove(organizationId) })
    },
    addFavoriteProject(context, { id, projectId }) {
      return db.collection('users').doc(id).update({ 'favorites.projects': FieldValue.arrayUnion(projectId) })
    },
    removeFavoriteProject(context, { id, projectId }) {
      return db.collection('users').doc(id).update({ 'favorites.projects': FieldValue.arrayRemove(projectId) })
    },
    async markPurchaseAsDelivered(contex, { purchaseId }) {
      await db.collection('purchases').doc(purchaseId).update({ shipmentStatus: 'delivered' })
      return true
    },

    // Other
    async login({ dispatch }, { email, password }) {
      const { user: { uid } } = await auth.signInWithEmailAndPassword(email, password)
      return dispatch('bind', uid)
    },
    async logout({ dispatch }) {
      await auth.signOut()
      return dispatch('unbind')
    },
    async setPassword({ dispatch }, { oobCode, password }) {
      await auth.verifyPasswordResetCode(oobCode)
      await auth.confirmPasswordReset(oobCode, password)
      return true
    },
    async verifyEmailCode({ dispatch }, { oobCode }) {
      await auth.applyActionCode(oobCode)
      return true
    },
    async resetPassword(context, email) {
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (!user) throw new Error('There is no user with that email')
      await await callBackend('users/send-custom-password-reset-email', { email })
      return true
    },
    async sendCustomEmail(context, { id, subject, body, organizationId, projectId = null }) {
      const user = (await db.collection('users').doc(id).get()).data()
      return runCloudFunction('sendCustomEmail', { email: user.email, name: user.firstName, subject, body, organizationId, projectId })
    },
    async toggleAcceptedCheckbox(context, { organizationId, projectId, clubId, value }) {
      const storageURL = `properties/${organizationId}/projects/${projectId}/clubs`
      const collectionRef = db
        .collection(storageURL)
        .doc(clubId)
      await collectionRef.set(
        {
          collaboratorUser: {
            hasAcceptedCheckbox: value,
          },
        },
        {
          merge: true,
        },
      )
    },
  },
}
