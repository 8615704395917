import { firestoreAction } from 'vuexfire'
import { db, FieldValue, uploadFileAndGetUrl, runCloudFunction, callBackend } from '@/services/firebase'
import i18n from '@/plugins/i18n'
import sidebarItems from './sidebarItems'
import rolesEnum from '@/enums/rolesEnum'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import logEvent from '@/utils/logEvent'
import getClusterCurrentRoleFromBooleans from '@/enums/utils/getClusterCurrentRoleFromBooleans'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbProjects: [],
    dbManagers: [],
    dbPlayers: [],
    dbStats: null,
  }),
  getters: {
    data: state => state.dbData,
    projects: (state, getters, rootState, rootGetters) => rootGetters['organization/projects'].length
      ? rootGetters['organization/projects'].filter(p => p.clusterId === state.dbData?.id)
      : state.dbProjects.map(project => ({
        ...project,
        get status() {
          if (!this.active) return 'archived'
          if (!this.published) return 'unpublished'
          return 'upToDate'
        },
      })),
    members: state => [...new Set([
      ...state.dbManagers.map(elem => ({ ...elem, role: 'manager' })),
    ])],
    players: state => state.dbPlayers,
    stats: state => state.dbStats,
    managers: state => state.dbManagers.map(elem => ({ ...elem, role: 'manager' })),
    isUserClusterManager: (state, getters, rootState, rootGetters) => rootGetters['user/managerClusterIds'].includes(state.dbData?.id) || rootGetters['organization/isUserAdmin'],
    isUserClusterCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabClusterIds'].includes(state.dbData?.id) || getters.isUserClusterManager,
    sidebarItems: (state, getters) => sidebarItems.filter(item => item.conditions === 'all'
     || item.conditions.some(cond => cond.role === getters.userRole)),
    userRole: (state, getters, rootState, rootGetters) => getClusterCurrentRoleFromBooleans(getters.isUserClusterManager, getters.isUserClusterCollaborator),
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/clusters`).doc(clusterId),
    )),
    bindProjects: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbProjects',
      db.collection(`properties/${organizationId}/projects`)?.where('clusterId', '==', clusterId),
    )),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbPlayers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`, 'array-contains', rolesEnum.FAN),
    )),
    bindManagers: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbManagers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byCluster.${clusterId}.roles`, 'array-contains', 'manager'),
    )),
    bindStats: firestoreAction(({ bindFirestoreRef }, { organizationId, clusterId }) => bindFirestoreRef(
      'dbStats',
      db.collection(`properties/${organizationId}/clusters/${clusterId}/stats`).doc('basic'),
    )),
    getSubscribersCsv: ({ rootState }, { organizationId, clusterId }) => callBackend('exports/cluster/get-subscribers-csv', { organizationId, clusterId, lang: rootState.locale }),
    getStaffCsv: ({ rootState }, { organizationId, clusterId }) => callBackend('exports/cluster/get-staff-csv', { organizationId, clusterId, lang: rootState.locale }),
    // Create
    async create(context, { organizationId, data }) {
      const clusterRef = db.collection(`properties/${organizationId}/clusters`).doc()
      const cluster = {
        ...data,
        id: clusterRef.id,
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await clusterRef.set(cluster)
    },
    async createClone({ rootState }, { organizationId, clusterId }) {
      const originalCluster = rootState.organization.dbClusters.find(cluster => cluster.id === clusterId)
      const newClusterRef = db.collection(`properties/${organizationId}/clusters`).doc()
      const newCluster = {
        ...originalCluster,
        id: newClusterRef.id,
        name: `${originalCluster.name} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newClusterRef.set(newCluster)
      logEvent({ action: 'create', entityType: 'cluster', entity: newCluster })
      return newClusterRef.id
    },
    // Delete
    async delete(context, { organizationId, cluster }) {
      await Promise.all(context.rootState.organization.dbProjects.filter(p => p.clusterId === cluster.id)
        .map(project => context.dispatch('project/delete', { organizationId, project }, { root: true })))

      await callBackend('recursive-delete', { path: `properties/${organizationId}/clusters/${cluster.id}` })
      logEvent({ action: 'delete', entityType: 'cluster', entity: cluster })
      return true
    },
    // Update
    async update(context, { organizationId, clusterId, data }) {
      const storagePath = `properties/${organizationId}/clusters/${clusterId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const dataToUpdate = { ...data, logo, banner }
      await db.collection(`properties/${organizationId}/clusters`).doc(clusterId).update(dataToUpdate)
      return true
    },

    async addStaffer({ state, getters, dispatch, rootState }, { organizationId, clusterId, email, role }) {
      const entitiesToSubscribe = [
        { type: 'organization', role: rolesEnum.COLLABORATOR, organizationId },
        { type: 'cluster', role, organizationId, clusterId },
      ]
      const lang = rootState.locale
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (user) {
        const isAlreadyStaffer = getters.members.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.members.find(s => s.id === user.id)
          if (staffer.role === role) return false
          await dispatch('removeStaffer', { organizationId, clusterId, user: staffer, role })
        }
        await Promise.all(entitiesToSubscribe.map(entity => subscribeToEntity({ userId: user.id, entity, lang })))
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({ entitiesToSubscribe, id: email, secretKey, createdAt: FieldValue.serverTimestamp() })
        runCloudFunction('sendUserJoinedEmail', {
          email,
          name: '',
          role,
          isNewUser: true,
          platform: 'web',
          link: await getEmailLoginLink({ isNewUser: true, email, secretKey }),
          lang,
          organizationId,
          clusterId,
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'cluster', entity: state.dbData, targetUser })
      return true
    },
    async removeStaffer({ state }, { organizationId, clusterId, user, role }) {
      const entity = { type: 'cluster', role, organizationId, clusterId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'cluster', entity: state.dbData, targetUser: user })
      return true
    },
    async publish({ state, getters }, { organizationId, clusterId }) {
      const projectRef = db.collection(`properties/${organizationId}/clusters`).doc(clusterId)
      projectRef.update({ published: true, publishedAt: FieldValue.serverTimestamp() })
      // TODO
      /* if (!getters.doNotSendNotificationOnPublish) {
        callBackend('notifications/push/create', {
          scope: 'organization',
          context: { organizationId, clusterId },
          data: {
            isCustom: true,
            title: i18n.t('pushNotifications.transactional.projectPublished.title', [state.dbData.name]),
            body: i18n.t('pushNotifications.transactional.projectPublished.body', [state.dbData.name]),
            linkType: 'clusterDetail',
          },
        })
      }
      logEvent({ action: 'update', entityType: 'cluster', entity: { ...state.dbData, published: true, publishedAt: FieldValue.serverTimestamp() } }) */
      return true
    },
    async archive(context, { organizationId, cluster }) {
      await db.collection(`properties/${organizationId}/clusters`).doc(cluster.id).update({ active: false })
      logEvent({ action: 'archive', entityType: 'cluster', entity: cluster })
      return true
    },
    async unArchive(context, { organizationId, cluster }) {
      await db.collection(`properties/${organizationId}/clusters`).doc(cluster.id).update({ active: true })
      logEvent({ action: 'unarchive', entityType: 'cluster', entity: cluster })
      return true
    },
  },
}
