// We use our custom variable instead of NODE_ENV because vue-cli-service build automatically sets NODE_ENV=production in both dev and prod environments.
// If we manually set NODE_ENV=development or use --mode development in build:dev script, then webpack builds the bundle with no hashes and no sourcemaps, which we don't want
export default process.env.VUE_APP_IS_PRODUCTION === 'true'
  ? {
    apiKey: 'AIzaSyCmuu_sYgDo3640UP9alHFTRQYswqhy5WQ',
    authDomain: 'owqlo-master-production-id.firebaseapp.com',
    databaseURL: 'https://owqlo-master-production-id.firebaseio.com',
    projectId: 'owqlo-master-production-id',
    storageBucket: 'owqlo-master-production-id.appspot.com',
    messagingSenderId: '737501983455',
    appId: '1:737501983455:web:4405fd2789ac0bed252f6a',
    measurementId: 'G-D6040H9HGB',
  }
  : {
    apiKey: 'AIzaSyAfaAEDjw94rD3HOehsR9kZc5HfQw7m9i8',
    authDomain: 'owqlo-master-dev.firebaseapp.com',
    databaseURL: 'https://owqlo-master-dev.firebaseio.com',
    projectId: 'owqlo-master-dev',
    storageBucket: 'owqlo-master-dev.appspot.com',
    messagingSenderId: '453505352745',
    appId: '1:453505352745:web:4721c772e8c5cbae460838',
    measurementId: 'G-T0GWD1DXHZ',
  }
