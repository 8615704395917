export default {
  namespaced: true,
  state: () => ({
    type: null,
    show: false,
    text: null,
    color: null,
    iconColor: null,
    icon: null,
  }),
  mutations: {
    alertSuccess(state, text) {
      state.type = 'success'
      state.text = text
      state.show = true
      state.color = '#dbefdc'
      state.iconColor = '#89D184'
      state.icon = 'check_circle'
    },
    alertWarning(state, text) {
      state.type = 'warning'
      state.text = text
      state.show = true
      state.color = '#404040'
      state.iconColor = 'white'
      state.icon = 'warning'
    },
    alertError(state, text) {
      state.type = 'error'
      state.text = text
      state.show = true
      state.color = '#df5555'
      state.iconColor = 'white'
      state.icon = 'error_outline'
    },
    closeAlertBox(state) {
      state.show = false
    },
  },
  actions: {
    openAlertBox({ commit }, [alertType, data]) {
      if ([
        'alertSuccess',
        'alertWarning',
        'alertError',
      ].includes(alertType)) {
        commit(alertType, data)
      }
      setTimeout(() => {
        commit('closeAlertBox')
      }, 5000)
    },
  },
}
