import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'activity/:activityId',
    name: 'activity',
    component: () => import('@/modules/activity/Activity'),
    redirect: { name: 'activity-edit' },
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, activityId } = to.params
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) await store.dispatch('organization/bind', organizationId)
      if (store.state.project.dbData?.id !== projectId) await store.dispatch('project/bind', { organizationId, projectId })
      if (store.state.activity.dbData?.id !== activityId) {
        const entity = await store.dispatch('activity/bind', { organizationId, projectId, activityId })
        if (!entity) next({ name: '404' })
        await store.dispatch('activity/bindComments', { organizationId, projectId, activityId })
      }
      const streamingId = store.state.activity.dbData?.streamingChannel
      if (store.state.activity.dbData?.type === 'streaming' && store.state.streaming.dbData?.id !== streamingId) {
        await store.dispatch('streaming/bind', { organizationId, streamingId })
      }
      store.commit('loader/hide')
      next()
    },
    children: [
      {
        path: 'content',
        name: 'activity-edit',
        component: () => import('@/modules/activity/EditActivity'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.activities')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'comments',
        name: 'activity-comments',
        component: () => import('@/modules/activity/CommentList'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('common.comments')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
      {
        path: 'streaming-stats',
        name: 'activity-streaming-stats',
        component: () => import('@/modules/activity/StreamingStats'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('streamingStats.title')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
    ],
  },
]
