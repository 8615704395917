export default {
  namespaced: true,
  state: () => ({
    date: new Date().toISOString().substr(0, 10),
    range: 'week',
  }),
  getters: {
    date: state => state.date,
    currentRange: state => state.range,
  },
  mutations: {
    updateDate(state, data) {
      state.date = data
    },
    updateRange(state, data) {
      state.range = data
    },
  },
}
