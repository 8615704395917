import { firestoreAction } from 'vuexfire'
import { db, FieldValue } from '@/services/firebase'
import { strToUniqueId } from '@/utils/formatters'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
  }),
  getters: {
    data: state => state.dbData,
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId, teamId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),

    // Create
    async create(context, { organizationId, projectId, clubId, data }) {
      const teamRef = db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${data.cohortId}/teams`)
        .doc(`${clubId}-${strToUniqueId('')}`)
      await teamRef.set({
        ...data,
        id: teamRef.id,
        clubId,
        isApproved: false,
        attachments: [],
        dataByStageId: {},
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
        projectId,
        cohortId: data.cohortId,
      })
      // logEvent({ action: 'create', entityType: 'team', entity: team })
      return teamRef.id
    },

  },
}
