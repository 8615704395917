import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import user from '@/modules/user/store'
import superadmin from '@/modules/superadmin/store'
import organization from '@/modules/organization/store'
import project from '@/modules/project/store'
import activity from '@/modules/activity/store'
import stats from '@/modules/stats/store'
import alert from '@/modules/alert/store'
import loader from '@/modules/loader/store'
import comment from '@/modules/comment/store'
import streaming from '@/modules/streaming/store'
import pushNotifications from '@/modules/pushNotifications/store'
import dynamicForm from '@/modules/dynamicForm/store'
import competition from '@/modules/competition/store'
import club from '@/modules/club/store'
import clubEnrollmentForm from '@/modules/clubEnrollmentForm/store'
import academy from '@/modules/academy/store'
import team from '@/modules/team/store'
import projectUsers from '@/modules/projectUsers/store'
import cluster from '@/modules/cluster/store'
import guild from '@/modules/guild/store'
import guildMatches from '@/modules/guildMatches/store'
import post from '@/modules/post/store'
import calendar from '@/modules/calendar/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: null,
  },
  mutations: {
    ...vuexfireMutations,
    setLocale(state, code) {
      state.locale = code
    },
  },
  modules: {
    user,
    superadmin,
    organization,
    project,
    activity,
    stats,
    alert,
    loader,
    comment,
    streaming,
    pushNotifications,
    dynamicForm,
    competition,
    club,
    clubEnrollmentForm,
    academy,
    team,
    projectUsers,
    cluster,
    guild,
    guildMatches,
    post,
    calendar,
  },
})
