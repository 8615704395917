// eslint-disable-next-line import/no-extraneous-dependencies
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VTooltip from 'v-tooltip'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VuetifyConfirm from 'vuetify-confirm'
import VDataFooter from 'vuetify/lib/components/VDataIterator/VDataFooter'
import VData from 'vuetify/lib/components/VData'
// Locales
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import i18n from '@/plugins/i18n'

Vue.use(Vuetify)
Vue.use(VTooltip)
VData.options.props.itemsPerPage.default = 200
VDataFooter.options.props.itemsPerPageOptions.default = () => [50, 100, 200, -1]

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2acccc',
        secondary: '#404040',
        accent: '#82B1FF',
        error: '#e02020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        warningDark: '#C84F00',
        inactive: '#F5F5F5',
        archived: '#000000',
        unpublished: '#ff8f45',
        pending: '#f8c125',
        upToDate: '#89d184',
        yellow10: '#FEF9E9',
        grey05: '#F7F7F7',
        grey50: '#979797',
        green10: '#F3FAF3',
        green80: '#89D184',
        red80: '#DF5555',
        acqua10: '#E1FFFF',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { en, es },
    current: 'en',
  },
})

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: i18n.t('common.yes'),
  buttonFalseText: i18n.t('common.no'),
})
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md',
})
Vue.use(VueTelInputVuetify, {
  vuetify,
})
export default vuetify
