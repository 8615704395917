import rolesEnum from '@/enums/rolesEnum'

export default (isManager, isSubmanager, isCoach) => {
  if (isManager) {
    return rolesEnum.MANAGER
  } if (isSubmanager) {
    return rolesEnum.SUBMANAGER
  } if (isCoach) {
    return rolesEnum.COACH
  }
  return 'not-role'
}
