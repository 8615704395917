import { firestoreAction } from 'vuexfire'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj, FieldValue, callBackend, runCloudFunction } from '@/services/firebase'
import sidebarItems from './sidebarItems'
import getEmailLoginLink from '@/utils/getEmailLoginLink'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbTeams: [],
    dbStaff: [],
    dbPlayers: [],
  }),
  getters: {
    data: state => state.dbData,
    teams: (state, getters, rootState, rootGetters) => state.dbTeams.map(team => ({
      ...team,
      nameWithCohort: `${rootGetters['competition/cohorts'].find(c => c.id === team.cohortId)?.name}${team.suffix ? ` - ${team.suffix}` : ''}`,
    })),
    staff: state => state.dbStaff,
    players: state => state.dbPlayers,
    sidebarItems: (state, getters) => sidebarItems,
    isClubCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabClubIds'].includes(state.dbData?.id),
  },
  actions: {
    // Read
    async read(context, { organizationId, projectId, clubId }) { return (await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId).get()).data() },
    readPlayers: async (context, { organizationId, projectId, cohortId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players`).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    readPrivatePlayerData: async (context, { organizationId, projectId, cohortId, playerId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players/${playerId}/private`).doc('private')
      const document = await collectionSnap.get()
      return document.data()
    },
    readClubStaff: async (context, { organizationId, projectId, clubId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/clubs/${clubId}/staff`).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, clubId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId),
    )),
    bindTeams: firestoreAction(({ bindFirestoreRef }, { clubId, projectId }) => bindFirestoreRef(
      'dbTeams',
      db.collectionGroup('teams').where('clubId', '==', clubId).where('projectId', '==', projectId),
    )),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId, teamId }) => bindFirestoreRef(
      'dbPlayers',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players`).where('teamId', '==', teamId),
    )),
    bindStaff: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, cohortId, teamId }) => bindFirestoreRef(
      'dbStaff',
      db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}/staff`),
    )),
    unbindTeams: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbTeams')),
    unbindStaff: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbStaff')),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPlayers')),

    // Update
    async update(context, { organizationId, projectId, clubId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/clubs/${data.id}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const club = { ...data, logo, updatedAt: FieldValue.serverTimestamp() }
      await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(clubId).update(club)
      // logEvent({ action: 'update', entityType: 'club', entity: club }) // TODO: LOGS CLUB
      return true
    },

    updateTeamSuffix(context, { organizationId, projectId, cohortId, teamId, suffix }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ suffix })
    },
    async updateByClubmanager({ state, getters }, { organizationId, projectId, updatedType }) {
      if (getters.isClubCollaborator) {
        await db.collection(`properties/${organizationId}/projects/${projectId}/clubs`).doc(getters.data.id).update({
          [`managerUpdates.${updatedType}`]: FieldValue.serverTimestamp(),
        })
      }
    },

    async modifyIsApproved({ state }, { organizationId, projectId, cohortId, teamId, value }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ isApproved: value })
    },

    // Delete
    async delete(context, { organizationId, projectId, club }) {
      const { id: clubId } = club
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/clubs/${clubId}` })
      // logEvent({ action: 'delete', entityType: 'club', entity: club }) // TODO: LOGS CLUB
      return true
    },

    // Players
    async createPlayer({ dispatch, rootGetters }, { organizationId, projectId, cohortId, teamId, data }) {
      let avatarUrl
      const {
        avatar = null,
        firstName,
        lastName,
        dni,
        birthdate,
        number,
        email,
        parentEmail,
        licenseCode,
      } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players`
      const playerRef = db.collection(storageURL).doc(Math.random().toString(36).slice(2, 8))
      const privateRef = db.collection(`${storageURL}/${playerRef.id}/private`).doc('private')
      if (avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${playerRef.id}`, avatar)
      const publicData = {
        id: playerRef.id,
        teamId,
        firstName,
        lastName,
        number,
        ...(licenseCode ? ({ licenseCode }) : ({ licenseCode: null })),
        ...(avatar && ({ avatar: avatarUrl })),
        cohortId,
        projectId,
        createdAt: FieldValue.serverTimestamp(),
      }
      console.log('🚀 ~ file: store.js ~ line 119 ~ createPlayer ~ publicData', publicData)
      const privateData = {
        id: 'private',
        ...(dni ? ({ dni }) : ({ dni: null })),
        ...(birthdate ? ({ birthdate }) : ({ birthdate: null })),
        ...(email ? ({ email }) : ({ email: null })),
        ...(parentEmail ? ({ parentEmail }) : ({ parentEmail: null })),
        createdAt: FieldValue.serverTimestamp(),
      }
      console.log('🚀 ~ file: store.js ~ line 127 ~ createPlayer ~ privateData', privateData)
      await privateRef.set(privateData)
      await playerRef.set(publicData)

      if ((email || parentEmail) && rootGetters['project/hasEnrollRequiresPlayerCodeFeatures']) {
        dispatch('sendEmailPlayerAddInTeam', { playerId: playerRef.id, organizationId, projectId, cohortId })
      }

      return true
    },

    async sendEmailPlayerAddInTeam({ rootState }, { playerId, organizationId, projectId, cohortId }) {
      const privateData = (await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players/${playerId}/private`).doc('private').get()).data()
      const secretKey = Math.random().toString(36).substring(2, 15)
      for (const email of [privateData.email, privateData.parentEmail].filter(v => v)) {
        if (email) {
          const dynamicLink = await getEmailLoginLink({ isNewUser: true, email, secretKey, platform: 'app' })
          const payload = {
            templateId: 'd-b2c6e4bb8d584aa385de82263a487765',
            email,
            dynamicTemplateData: {
              projectName: rootState.project.dbData.name,
              lang: rootState.project.dbData.language,
              code: playerId,
              dynamicLink,
            },
          }
          runCloudFunction('sendDynamicTemplateEmail', payload)
        }
      }
    },

    async updatePlayer(context, { organizationId, projectId, cohortId, data }) {
      let avatarUrl
      const { id: playerId, avatar, firstName, lastName, dni, birthdate, number, email, parentEmail, licenseCode } = data
      const storageURL = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players`
      const playerRef = db.collection(storageURL).doc(playerId)
      const privateRef = db.collection(`${storageURL}/${playerId}/private`).doc('private')
      if (avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${playerRef.id}`, avatar)

      const publicData = {
        firstName,
        lastName,
        number,
        ...(licenseCode ? ({ licenseCode }) : ({ licenseCode: null })),
        ...(avatar && ({ avatar: avatarUrl })),
        updatedAt: FieldValue.serverTimestamp(),
      }
      const privateData = {
        ...(dni ? ({ dni }) : ({ dni: null })),
        ...(birthdate ? ({ birthdate }) : ({ birthdate: null })),
        ...(email ? ({ email }) : ({ email: null })),
        ...(parentEmail ? ({ parentEmail }) : ({ parentEmail: null })),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await privateRef.update(privateData)
      await playerRef.update(publicData)

      return true
    },

    async deletePlayer(context, { organizationId, projectId, cohortId, playerId }) {
      await await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/players/${playerId}` })
      return true
    },

    async deleteTeam(context, { organizationId, projectId, cohortId, teamId }) {
      await await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}` })
      return true
    },

    // Staff
    async createStaff(context, { organizationId, projectId, cohortId, teamId, data }) {
      let avatarUrl
      const storageURL = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}/staff`
      const staffRef = db
        .collection(storageURL)
        .doc()
      if (data.avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${staffRef.id}`, data.avatar)
      const publicData = {
        id: staffRef.id,
        ...data,
        ...(data.avatar && ({ avatar: avatarUrl })),
        createdAt: FieldValue.serverTimestamp(),
      }
      await staffRef.set(publicData)

      return true
    },
    async updateStaff(context, { organizationId, projectId, cohortId, teamId, data }) {
      let avatarUrl
      const storageURL = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}/staff`
      const collectionRef = db.collection(storageURL).doc(data.id)

      if (data.avatar) avatarUrl = await uploadFileAndGetUrl(`${storageURL}/${collectionRef.id}`, data.avatar)
      const publicData = {
        ...data,
        ...(data.avatar && ({ avatar: avatarUrl })),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await collectionRef.update(publicData)

      return true
    },

    async deleteStaff(context, { organizationId, projectId, cohortId, teamId, staffId }) {
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}/staff/${staffId}` })
      return true
    },

    // team attachments
    async updateTeamAttachments(context, { organizationId, projectId, cohortId, teamId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams/${teamId}`
      const attachments = await Promise.all(data.map(file => uploadFileAndGetObj(storagePath, file)))
      await db.collection(`properties/${organizationId}/projects/${projectId}/cohorts/${cohortId}/teams`).doc(teamId).update({ attachments })
      return true
    },
  },
}
