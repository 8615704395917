import { firestoreAction } from 'vuexfire'
import i18n from '@/plugins/i18n'
import { db, FieldValue, uploadFileAndGetUrl, callBackend, runCloudFunction } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import getOrganizationCurrentRoleFromBooleans from '@/enums/utils/getOrganizationCurrentRoleFromBooleans'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import sidebarItems from './sidebarItems'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbEventLog: null,
    dbClusters: [],
    dbProjects: [],
    dbAdmins: [],
    dbCollaborators: [],
    dbTerms: [],
  }),

  getters: {
    isUserAdmin: (state, getters, rootState, rootGetters) => rootGetters['user/adminOrganizationIds'].includes(state.dbData?.id) || rootGetters['superadmin/isUserSuperadmin'],
    isSoccer: state => state.dbData.sport === 'soccer',
    isBasketball: state => state.dbData.sport === 'basketball',
    isGuild: state => state.dbData.sport === 'guild',
    isUserCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabOrganizationIds'].includes(state.dbData?.id) || getters.isUserAdmin,
    rawEvents: state => state.dbEventLog?.events ?? [],
    projects: (state, getters, rootState) => state.dbProjects.map(project => ({
      ...project,
      get status() {
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
      isFavorite: rootState.user.dbData?.favorites?.projects?.includes(project.id),
    })),
    clusters: state => state.dbClusters.map(cluster => ({
      ...cluster,
      get status() {
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
    })),
    members: state => [...new Set([
      ...state.dbAdmins.map(elem => ({ ...elem, role: 'admin' })),
      ...state.dbCollaborators.map(elem => ({ ...elem, role: 'collaborator' })),
    ])],
    admins: state => state.dbAdmins.map(elem => ({ ...elem, role: 'admin' })),
    collaborators: state => state.dbCollaborators.map(elem => ({ ...elem, role: 'collaborator' })),
    data: state => state.dbData,
    terms: state => state.dbTerms,
    sidebarItems: (state, getters) => sidebarItems.filter(item => item.conditions === 'all'
     || item.conditions.some(cond => cond.role === getters.userRole)),
    userRole: (state, getters) => getOrganizationCurrentRoleFromBooleans(getters.isUserAdmin, getters.isUserCollaborator),
  },
  actions: {
    // Read
    async read(context, organizationId) { return (await db.collection('properties').doc(organizationId).get()).data() },
    bind: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbData', db.collection('properties').doc(id))),
    bindEventLog: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbEventLog', db.collection(`properties/${id}/eventLog`).doc('eventLog'))),
    bindClusters: firestoreAction(({ bindFirestoreRef }, organizationId) => bindFirestoreRef('dbClusters', db.collection(`properties/${organizationId}/clusters`))),
    bindProjects: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbProjects', db.collection(`properties/${id}/projects`))),
    bindAdmins: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbAdmins',
      db.collection('users').where(`roles.byProperty.${id}.roles`, 'array-contains', 'admin'),
    )),
    bindCollaborators: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef(
      'dbCollaborators',
      db.collection('users').where(`roles.byProperty.${id}.roles`, 'array-contains', 'collaborator'),
    )),
    bindTerms: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbTerms', db.collection(`properties/${id}/terms`))),
    readSubscribers: async ({ state }, { organizationId }) => (await Promise.all(state.dbProjects.map(async project => (await db.collection('users')
      .where(`roles.byProperty.${organizationId}.byProject.${project.id}.roles`, 'array-contains', 'subscriber')
      .get()).docs.map(user => user.data())))).flat(),
    getSubscribersCsv: ({ rootState }, { organizationId }) => callBackend('exports/organization/get-subscribers-csv', { organizationId, lang: rootState.locale }),

    // Create
    async createClone({ rootState }, { organizationId }) {
      const originalOrganization = rootState.superadmin.dbOrganizations.find(organization => organization.id === organizationId)
      const newOrganizationRef = db.collection('properties').doc()
      const name = `${originalOrganization.name} - ${i18n.t('common.copy')}`

      await newOrganizationRef.set({
        ...originalOrganization,
        id: newOrganizationRef.id,
        name,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      return newOrganizationRef.id
    },
    // Update
    async update({ state, getters }, { organizationId, data }) {
      const storagePath = `properties/${organizationId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const firebaseData = {
        ...data,
        logo,
        ...(
          logo
          && ({
            logos: [
              {
                id: '',
                name: '',
                publicUrl: logo,
                privateUrl: '',
              },
            ],
          })
        ),
        banner,
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update organization data
      await db.collection('properties').doc(organizationId).set(firebaseData, { merge: true })

      logEvent({ action: 'update', entityType: 'organization', entity: state.dbData })
      return true
    },

    // Privacy Policy
    async updateTerms({ state, getters }, { organizationId, defaultLanguage, termsList }) {
      const firebaseData = {
        terms: {
          languages: termsList.map(term => term.id),
          defaultLanguage,
        },
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update organization
      await db.collection('properties').doc(organizationId).update(firebaseData)

      // Remove terms
      if (getters.terms.length) {
        const termsTodelete = getters.terms.filter(t => !termsList.map(t1 => t1.id).includes(t.id))
        await Promise.all(termsTodelete.map(term => db.collection(`properties/${organizationId}/terms`).doc(term.id).delete()))
      }

      // update terms
      await Promise.all(termsList.map(term => db.collection(`properties/${organizationId}/terms`).doc(term.id).set(term)))
      logEvent({ action: 'update', entityType: 'organization', entity: state.dbData })
      return true
    },

    // Delete
    delete(context, { organizationId }) {
      return callBackend('recursive-delete', { path: `properties/${organizationId}` })
    },

    // Other
    async addStaffer({ state, getters, dispatch, rootState }, { organizationId, email, role }) {
      const entity = { type: 'organization', role, organizationId }
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      const lang = rootState.locale
      if (user) {
        const isAlreadyStaffer = getters.members.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.members.find(s => s.id === user.id)
          if (staffer.role === role) return false
          await dispatch('removeStaffer', { organizationId, user: staffer, role })
        }
        await subscribeToEntity({ userId: user.id, entity, lang })
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({ entitiesToSubscribe: [entity], id: email, secretKey, createdAt: FieldValue.serverTimestamp() })
        runCloudFunction('sendUserJoinedEmail', {
          email,
          name: '',
          role,
          isNewUser: true,
          platform: 'web',
          link: await getEmailLoginLink({ isNewUser: true, email, secretKey }),
          lang,
          organizationId: state.dbData.id,
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'organization', entity: state.dbData, targetUser })
      return true
    },
    async removeStaffer({ state }, { organizationId, user, role }) {
      const entity = { type: 'organization', role, organizationId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'organization', entity: state.dbData, targetUser: user })
      return true
    },
  },
}
