import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend, runCloudFunction } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getUserEntityIds from '@/utils/getUserEntityIds'
import rolesEnum from '@/enums/rolesEnum'
import getEmailLoginLink from '@/utils/getEmailLoginLink'
import { subscribeToEntity, unsubscribeFromEntity } from '@/utils/entitySubscriptions'
import getStaffRolesByRoleAndProjectType from '@/enums/utils/getStaffRolesByRoleAndProjectType'

export default {
  namespaced: true,
  state: () => ({
    dbFans: [],
    dbPlayers: [],
    dbCoaches: [],
    dbManagers: [],
    dbSubmanagers: [],
    dbMatchEditors: [],
    dbPlayer: null,
    playerListFilters: {
      selectedTeams: [],
      search: null,
    },
    fanListFilters: {
      selectedCountries: [],
      search: null,
    },
  }),
  getters: {
    rawFans: state => state.dbFans,
    rawPlayers: state => state.dbPlayers,
    player: (state, getters, rootState, rootGetters) => ({
      ...state.dbPlayer,
      teamId: getUserEntityIds(
        state.dbPlayer?.roles?.byProperty?.[rootGetters['organization/data'].id]?.byProject[rootGetters['project/data'].id],
        'team',
        rolesEnum.PLAYER,
      )[0],
    }),
    staff: (state, getters, rootState, rootGetters) => [...new Set([
      ...getters.managers.map(elem => ({ ...elem, role: rolesEnum.MANAGER })),
      ...getters.submanagers.map(elem => ({ ...elem, role: rolesEnum.SUBMANAGER })),
      ...getters.coaches.map(elem => ({ ...elem, role: rolesEnum.COACH })),
      ...getters.matchEditors.map(elem => ({ ...elem, role: rolesEnum.MATCHEDITOR })),
    ])],
    playerListFilters: state => state.playerListFilters,
    fanListFilters: state => state.fanListFilters,
    staffRoles: (state, getters, rootState, rootGetters) => getStaffRolesByRoleAndProjectType(rootGetters['project/userRole'], rootGetters['project/data'].type),
    coaches: (state, getters, rootState, rootGetters) => state.dbCoaches,
    managers: state => state.dbManagers,
    submanagers: state => state.dbSubmanagers,
    matchEditors: state => state.dbMatchEditors,
  },
  mutations: {
    updatePlayerListFilters(state, params) {
      state.playerListFilters = params
    },
    updateFanListFilters(state, params) {
      state.fanListFilters = params
    },
  },
  actions: {

    /*
    * Staff and Students
    */
    bindFans: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbFans',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.FAN),
    )),
    bindPlayers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbPlayers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.PLAYER),
    )),
    unbindPlayers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbPlayers')),
    bindPlayer: firestoreAction(({ bindFirestoreRef }, userId) => bindFirestoreRef(
      'dbPlayer',
      db.collection('users').doc(userId),
    )),
    bindCoaches: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCoaches',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.COACH),
    )),
    bindMatchEditors: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbMatchEditors',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains', rolesEnum.MATCHEDITOR),
    )),
    unbindCoaches: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbCoaches')),
    async getParentsData(context, userId) {
      const userRef = (await db.collection('users').doc(userId).get()).data()
      return userRef?.linkedUsers?.parents && Promise.all(
        userRef.linkedUsers.parents.map(async parentId => (await db.collection('users').doc(parentId).get()).data()),
      )
    },
    bindManagers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbManagers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains-any', ['admin', 'manager']),
    )),
    unbindManagers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbManagers')),
    bindSubmanagers: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbSubmanagers',
      db.collection('users').where(`roles.byProperty.${organizationId}.byProject.${projectId}.roles`, 'array-contains-any', ['collaborator', 'submanager']),
    )),
    getSubscribersCsv: (context, { organizationId, projectId }) => callBackend('exports/project/get-subscribers-csv', { organizationId, projectId }),
    getFansCsv: (context, { organizationId, projectId }) => callBackend('exports/project/get-subscribers-csv', { organizationId, projectId }), // TODO: make sure endpoint exists and is running
    getEnrollmentsCsv: (context, { organizationId, projectId }) => callBackend('exports/project/get-enrollments-csv', { organizationId, projectId }),
    unbindSubmanagers: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbSubmanagers')),
    async addStaffer({ getters, rootGetters }, { organizationId, projectId, role, teamIds = [], email }) {
      const entitiesToSubscribe = [
        { type: 'organization', role: rolesEnum.COLLABORATOR, organizationId },
        { type: 'project', role, organizationId, projectId },
        ...teamIds.map(teamId => ({ type: 'team', role, organizationId, projectId, teamId })),
      ]
      const lang = rootGetters['project/data']?.language
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (user) {
        const isAlreadyStaffer = getters.staff.map(member => member.id).includes(user.id)
        if (isAlreadyStaffer) {
          const staffer = getters.staff.find(s => s.id === user.id)
          if (role !== rolesEnum.COACH && staffer.role === role) return false
          // await dispatch('removeStaffer', { organizationId, projectId, user: staffer, role: staffer.role })
        }
        await Promise.all(entitiesToSubscribe.map(entity => subscribeToEntity({ userId: user.id, entity, lang })))
      } else {
        const secretKey = Math.random().toString(36).substring(2, 15)
        const invitationRef = db.collection('invitations').doc(email)
        await invitationRef.set({
          entitiesToSubscribe,
          id: email,
          secretKey,
          createdAt: FieldValue.serverTimestamp(),
        })
        const platform = role === rolesEnum.MATCHEDITOR ? 'app' : 'web'
        runCloudFunction('sendUserJoinedEmail', {
          email,
          name: '',
          role,
          isNewUser: true,
          platform,
          link: await getEmailLoginLink({ isNewUser: true, email, secretKey, platform }),
          lang,
          organizationId,
          projectId,
        })
      }
      const targetUser = user ? { ...user, role } : { id: null, firstName: email, lastName: '', role }
      logEvent({ action: 'addUser', entityType: 'project', entity: rootGetters['project/data'], targetUser })
      return true
    },
    async removeStaffer({ rootGetters }, { organizationId, projectId, user, role }) {
      const entity = { type: 'project', role, organizationId, projectId }
      await unsubscribeFromEntity({ userId: user.id, entity })
      logEvent({ action: 'removeUser', entityType: 'project', entity: rootGetters['project/data'], targetUser: { ...user, role: 'collaborator' } })
      return true
    },
    async addTeamsToCoaches({ rootGetters }, { organizationId, projectId, userId, teamIds }) {
      const lang = rootGetters['project/data']?.language
      await Promise.all(teamIds.map(team => subscribeToEntity({
        userId,
        entity: { type: 'team', role: 'coach', organizationId, projectId, teamId: team },
        lang,
      })))
    },
    async removeTeamsFromCoaches({ state }, { organizationId, projectId, userId, teamIds }) {
      await Promise.all(teamIds.map(team => unsubscribeFromEntity({
        userId,
        entity: { type: 'team', role: 'coach', organizationId, projectId, teamId: team },
      })))
    },
    removePlayer: (context, { organizationId, projectId, userId, role }) => callBackend('projects/users/unsubscribe', { organizationId, projectId, userId, role }),

    /**
    * Activities
    */
    async readActivities(context, { organizationId, projectId, playerId }) {
      const activitiesSnap = await db
        .collection(`properties/${organizationId}/projects/${projectId}/activities`)
        .where('userIds', 'array-contains-any', [playerId, 'all'])
        .get()

      return activitiesSnap.docs.map(snap => snap.data())
    },

  },
}
