import { firestoreAction } from 'vuexfire'
import { db } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbDataFilled: true,
  }),
  getters: {
    data: state => ({ ...state.dbData }),
    allFieldsFilledOut: state => state.dbDataFilled,
  },
  mutations: {
    updateField(state, payload) {
      const existsAtIndex = state.dbData?.fields?.findIndex(field => field?.id === payload?.id)

      if (payload?.id && existsAtIndex !== -1) {
        state.dbData.fields[existsAtIndex] = payload
      } else {
        // generate unique firebase id
        const collectionRef = db.collection('forms').doc()
        const newField = {
          ...payload,
          id: collectionRef.id,
        }
        state.dbData?.fields ? state.dbData.fields.push(newField) : state.dbData = { ...state.dbData, fields: [newField] }
      }

      state.dbData.fields = [...state.dbData.fields]
    },
    deleteField(state, payload) {
      state.dbData.fields = state.dbData.fields.filter(field => field?.id !== payload)
    },
    changeFilledState(state, payload) {
      state.dbDataFilled = payload
    },
  },
  actions: {
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('enrollment'),
    )),
    async readForm(context, { organizationId, projectId }) {
      return (await db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('enrollment').get()).data()
    },
    async updateForm({ state }, { organizationId, projectId }) {
      const { dbData } = state
      const formRef = db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('enrollment')
      const { fields } = dbData
      const parsedData = fields.map(field => field?.options ? ({ ...field, options: field.options.filter(option => option?.label) }) : field)
      await formRef.set({ fields: parsedData })
    },
    async deletePage({ state }, { organizationId, projectId, pageId }) {
      const fieldsData = state.dbData.fields
        .filter(field => field.page !== pageId)
        .map(field => field.page > pageId ? ({ ...field, page: field.page - 1 }) : field)
      // update local state
      state.dbData.fields = [...fieldsData]
      // update ddbb
      const formRef = db.collection(`properties/${organizationId}/projects/${projectId}/forms`).doc('enrollment')
      await formRef.set({ fields: fieldsData }, { merge: true })
    },
  },
}
