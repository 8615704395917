<template>
  <v-layout justify-center align-center>
    <v-snackbar
      :value="alert.show"
      class="app-snackbar"
      top
      center
      elevation="0"
      min-width="60vw"
      :color="alert.color"
      :content-class="`app-snackbar__${alert.type}`"
    >
      <span>
        <v-icon :color="alert.iconColor" style="margin-right: 8px;"> {{ alert.icon }} </v-icon>
        <span :class="`app-snackbar__${alert.type}`">{{ alert.text }}</span>
      </span>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Alert',
  computed: {
    ...mapState(['alert']),
  },
}
</script>

<style lang="scss">
.v-snack__content {
  margin: auto !important;
  text-align: center !important;
}

.app-snackbar {
  &__error {
    color: white;
    background-color: #df5555;
  }

  &__success {
    color: black;
    background-color: #dbefdc;
  }

  &__warning {
    color: white;
    background-color: #404040;
  }
}

</style>
