import getBasePath from '@/utils/getBasePath'
import { callBackend } from '@/services/firebase'

const BASE_URL = getBasePath()

export default async ({ isNewUser, email = null, secretKey = null, platform = 'web' }) => {
  if (platform === 'web') {
    return isNewUser
      ? `${BASE_URL}/register?email=${encodeURIComponent(email)}&secretKey=${secretKey}`
      : `${BASE_URL}/redirect-after-login`
  }
  return isNewUser ? callBackend('users/get-dynamic-link', { path: 'register', params: { invitationId: email } })
    : callBackend('users/get-dynamic-link', { path: 'redirect-after-login', params: { } })
}
