export default [
  {
    id: 'settings',
    name: 'club.sections.settings',
    icon: 'settings',
    route: { name: 'club-edit' },
    conditions: 'all',
  },
  {
    id: 'teams',
    name: 'club.sections.teams',
    icon: 'groups',
    multiline: true,
    route: { name: 'club-teams' },
    conditions: 'all',
  },
  {
    id: 'trips',
    name: 'club.sections.trips',
    icon: 'hotel',
    multiline: true,
    conditions: 'all',
  },
  {
    id: 'divider-3',
    type: 'divider',
    isStyle: true,
    conditions: 'all',
  },
]
