import { firestoreAction } from 'vuexfire'
import http from 'axios'
import i18n from '@/plugins/i18n'
import { db, uploadFileAndGetUrl, uploadFileAndGetObj, FieldValue, callBackend, getBackend, getWaiverUrl, uploadWaiverFile } from '@/services/firebase'
import logEvent from '@/utils/logEvent'
import getPdfBlobFromImageFile from '@/utils/getPdfBlobFromImageFile'
import getMissingFields from '@/utils/getMissingFields'
import { getI18n } from '@/services/deepl'
import getProjectCurrentRoleFromBooleans from '@/enums/utils/getProjectCurrentRoleFromBooleans'
import rolesEnum from '@/enums/rolesEnum'
import imagesExtensionsEnum from '@/enums/imagesExtensionsEnum'
import sidebarItems from './sidebarItems'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbEnrollments: [],
    dbActivities: [],
    dbCategories: [],
    dbTerms: [],
    dbClubs: [],
    dbServices: [],
    missingDataDialog: false,
    confirmPublicationDialog: false,
  }),
  getters: {
    language: state => state.dbData?.language,
    type: state => state.dbData?.type,
    isPublished: state => state.dbData?.published,
    isShortTerm: state => state.dbData?.type === 'short-term',
    isLongTerm: state => state.dbData?.type === 'long-term',
    isCompetition: state => state.dbData?.type === 'competition',
    isAcademy: state => state.dbData?.type === 'academy',
    isGuild: state => state.dbData?.type === 'guild',
    isSubscriberApprovalRequired: state => state.dbData?.subscriberApproval?.required,
    hasCategoryFeatures: state => state.dbData?.features?.categories,
    hasRegionsFeatures: state => state.dbData?.features?.hasRegions,
    hasFormFeatures: state => state.dbData?.features?.form,
    hasClubPaymentFeature: state => state.dbData?.features?.clubPrice,
    hasMatchLocationsFeature: state => state.dbData?.features?.hasMatchLocations,
    hasEnrollRequiresPlayerCodeFeatures: state => state.dbData?.features?.enrollRequiresPlayerCode,
    hasDisableTeamEditByClubFeature: state => state.dbData?.features?.disableTeamEditByClub,
    hasDisablePlayerEditByClubFeature: state => state.dbData?.features?.disablePlayerEditByClub,
    doNotSendNotificationOnActivityPublish: state => state.dbData?.features?.doNotSendNotificationOnActivityPublish,
    doNotSendNotificationOnPublish: state => state.dbData?.features?.doNotSendNotificationOnPublish,
    needsTranslation: state => state.dbData?.features?.needsTranslation,
    isUserCollaborator: (state, getters, rootState, rootGetters) => rootGetters['user/collabProjectIds'].includes(state.dbData?.id),
    isUserAdmin: (state, getters, rootState, rootGetters) => rootGetters['organization/isUserAdmin'],
    isUserStaff: (state, getters, rootState, rootGetters) => rootGetters['organization/isUserAdmin'] || rootGetters['user/collabProjectIds'].includes(state.dbData?.id),
    isUserManager: (state, getters, rootState, rootGetters) => rootGetters['user/managerProjectIds'].includes(state.dbData?.id) || (state.dbData?.clusterId ? rootGetters['cluster/isUserClusterManager'] : rootGetters['organization/isUserAdmin']), // TODO: CRIS ROLES
    isUserSubmanager: (state, getters, rootState, rootGetters) => rootGetters['user/submanagerProjectIds'].includes(state.dbData?.id) || rootGetters['project/isUserManager'], // TODO: CRIS ROLES
    isUserCoach: (state, getters, rootState, rootGetters) => rootGetters['user/coachProjectIds'].includes(state.dbData?.id), // TODO: CRIS ROLES
    requirementStepsForPublish: (state, getters, rootState, rootGetters) => {
      const { features } = getters.data
      const requiredSteps = []
      const fields = [
        'logo',
        'banner',
        ...(!getters.isGuild ? ['date'] : []),
        ...(!getters.isGuild ? ['age'] : []),
        ...(!getters.isGuild ? ['cardDescription'] : []),
        ...(!getters.isGuild ? ['language'] : []),
      ]
      // @Project Settings required fields
      if (
        getMissingFields(fields, state.dbData).length
      ) {
        requiredSteps.push({
          data: 'missingFields',
          icon: 'settings',
          route: { name: 'project-edit' },
        })
      }
      if (getters.isAcademy && !rootGetters['academy/categories']?.length) {
        requiredSteps.push({
          data: 'noCategories',
          icon: 'settings',
          route: { name: 'project-categories-and-teams' },
        })
      }
      // 'academy' projects require at least 1 team
      if (getters.isAcademy && (!rootGetters[`${getters.type}/teams`].length)) {
        requiredSteps.push({
          data: 'noTeams',
          icon: 'settings',
          route: { name: 'project-categories-and-teams' },
        })
      }
      // right now all 'academy' project require terms, price || enrollPrice (payment), dynamic form
      if (features?.terms && !getters.terms.length) {
        requiredSteps.push({
          data: 'noTerms',
          icon: 'playlist_add_check',
          route: { name: 'project-waiver' },
        })
      }
      if (features?.form && (
        !rootGetters['dynamicForm/data'].fields
        || !rootGetters['dynamicForm/data']?.fields
          .every(field => ['text', 'label'].some(key => field[key])))
      ) {
        requiredSteps.push({
          data: 'noForm',
          icon: 'playlist_add_check',
          route: { name: 'project-form' },
        })
      }
      if (
        (features?.price) && (
          !getters.data.pricing?.currency
          || !getters.data.stripe?.accountId
          || !(getters.data.pricing?.priceOptions?.length || getters.data.pricing?.enrollPrice)
        )
      ) {
        requiredSteps.push({
          data: 'noPaymentSettings',
          icon: 'credit_card',
          route: { name: 'project-payments' },
        })
      }
      // @emails settings, to be reviewed when Ligas will be introduced
      // if (!getters.isCompetition && getMissingFields(['emailSettings'], state.dbData).length) requiredSteps.push('noEmailSettings')
      if (
        (
          getters.isShortTerm
          || getters.isLongTerm
        )
        && !getters.activities.filter(activity => activity.status === 'upToDate').length
      ) {
        requiredSteps.push({
          data: 'noActivitiesPublished',
          icon: 'sports',
          route: { name: 'project-calendar', params: { date: 'all' } },
        })
      }
      return requiredSteps
    },
    requirementStepsForOpenCompetition: (state, getters, rootState, rootGetters) => {
      const requiredSteps = []
      if (getters.isCompetition && !rootGetters['competition/cohorts']?.length) {
        requiredSteps.push({
          data: 'noCategoriesInCompetitionStructure',
          icon: 'camera',
          route: { name: 'competition-structure' },
        })
      }
      return requiredSteps
    },
    data: state => state.dbData,
    enrollments: state => state.dbEnrollments,
    originalActivities: state => state.dbActivities,
    activities: state => state.dbActivities.map(activity => ({
      ...activity,
      get status() {
        if (!this.active) return 'archived'
        if (!this.published) return 'unpublished'
        return 'upToDate'
      },
    })),
    categories: state => state.dbCategories,
    terms: state => state.dbTerms,
    clubs: state => state.dbClubs,
    services: state => state.dbServices,
    sidebarItems: (state, getters) => sidebarItems.filter(item => item.conditions === 'all'
     || item.conditions.some(cond => cond.role === getters.userRole && cond.type === getters.data?.type)),
    userRole: (state, getters, rootState, rootGetters) => getProjectCurrentRoleFromBooleans(rootGetters['project/isUserManager'], rootGetters['project/isUserSubmanager'], rootGetters['project/isUserCoach']),
    dataDialog: state => state.missingDataDialog,
  },
  mutations: {
    toggleDataDialog(state, open) {
      state.missingDataDialog = open
    },
    updateCompetitionCheckboxes(state, structure) {
      state.dbData = { ...state.dbData, structure }
    },
  },
  actions: {
    // Read
    async read(context, { organizationId, projectId }) { return (await db.collection(`properties/${organizationId}/projects`).doc(projectId).get()).data() },
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects`).doc(projectId),
    )),
    bindActivities: firestoreAction(({ bindFirestoreRef, rootGetters }, { organizationId, projectId }) => {
      const collectionRef = rootGetters['project/isUserSubmanager']
        ? db.collection(`properties/${organizationId}/projects/${projectId}/activities`)
        : db.collection(`properties/${organizationId}/projects/${projectId}/activities`).where('userIds', 'array-contains-any', [rootGetters['user/data'].id, 'all'])

      bindFirestoreRef('dbActivities', collectionRef)
    }),
    bindCategories: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbCategories',
      db.collection(`properties/${organizationId}/projects/${projectId}/categories`),
    )),
    readActivities: async (context, { organizationId, projectId }) => {
      const collectionSnap = await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    bindEnrollments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef(
      'dbEnrollments',
      db.collection(`properties/${organizationId}/projects/${projectId}/enrollments`),
    )),
    bindTerms: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbTerms', db.collection(`properties/${organizationId}/projects/${projectId}/terms`))),
    bindClubs: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbClubs', db.collection(`properties/${organizationId}/projects/${projectId}/clubs`))),
    unbindClubs: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbClubs')),
    async getDocumentWaiver(context, { projectId, userId }) {
      return getWaiverUrl(`${projectId}/${userId}.pdf`)
    },
    bindServices: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId }) => bindFirestoreRef('dbServices', db.collection(`properties/${organizationId}/projects/${projectId}/services`))),

    // Create
    async create(context, { organizationId, data }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc()
      const project = (({ presetType, rrobinStage, ...rest }) => ({
        ...rest,
        id: projectRef.id,
        active: true,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }))(data)
      await projectRef.set(project)
      if (data.type === 'competition') {
        const stageRrobin = {
          id: 'rrobin',
          type: 'rrobin',
          index: 0,
          matchesPerFixture: 1,
          fixtureType: 'addition',
          presetType: data.presetType,
          isDouble: data.rrobinStage.isDouble,
          hasSubgroups: data.presetType === 'nba',
        }
        db.collection(`properties/${organizationId}/projects/${projectRef.id}/stages`).doc('rrobin').set(stageRrobin)
        const stageElimination = (({ isDouble, hasSubgroups, ...rest }) => ({
          ...rest,
          id: 'elimination',
          type: 'elimination',
          index: 1,
          losersRoundIndexUpTo: -1,
        }))(stageRrobin)
        db.collection(`properties/${organizationId}/projects/${projectRef.id}/stages`).doc('elimination').set(stageElimination)
      }
      logEvent({ action: 'create', entityType: 'project', entity: project })
      return projectRef.id
    },

    async createClone(context, { organizationId, projectId }) {
      const { newProject } = await callBackend('projects/create-clone', { organizationId, projectId })
      logEvent({ action: 'create', entityType: 'project', entity: newProject })
      return true
    },

    async createEnrollment(context, { organizationId, projectId, enrollmentId, data, setPassword }) {
      if (data.waiver) {
        const [name, extension] = data.waiver.name.split('.')
        const imagesExtensions = ['png', 'jpg', 'jpeg']
        let pdFile = data.waiver
        if (imagesExtensions.includes(extension.toLowerCase())) {
          pdFile = await getPdfBlobFromImageFile(data.waiver)
        }
        await uploadWaiverFile(`${projectId}/${data.userId}.pdf`, pdFile)
      }

      if (data.info?.form) {
        const storagePath = `users/${data.userId}/membership/subscriber-project-${projectId}/`
        data.info.form = Object.fromEntries(await Promise.all(Object.entries(data.info.form)
          .map(async ([key, value]) => {
            if (typeof value === 'object' && value instanceof File) {
              return imagesExtensionsEnum.includes(value.name?.split('.').pop())
                ? [key, await uploadFileAndGetUrl(storagePath, value)]
                : [key, await uploadFileAndGetObj(storagePath, value)]
            }
            return [key, value]
          })))
      }

      const createdAt = FieldValue.serverTimestamp()
      const dataToSave = (({ waiver, ...rest }) => ({
        ...rest,
        createdAt,
        updatedAt: createdAt,
      }))(data)

      await db.collection(`properties/${organizationId}/projects/${projectId}/enrollments`).doc(enrollmentId).set(dataToSave)
      callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId, setPassword })
      return true
    },

    // Update
    async update({ getters, rootGetters }, { organizationId, project, data, categories }) {
      const { id: projectId } = project
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      const logo = await uploadFileAndGetUrl(storagePath, data.logo)
      const banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const ads = data.ads ? {
        link: data.ads.link ?? null,
        full: await uploadFileAndGetUrl(storagePath, data.ads.full),
        footer: await uploadFileAndGetUrl(storagePath, data.ads.footer),
      } : null

      const dataToUpdate = {
        ...data,
        ...(ads && ({ ads })),
        date: data.date ? data.date : FieldValue.delete(),
        deadline: data.deadline ? data.deadline : FieldValue.delete(),
        logo,
        banner,
        updatedAt: FieldValue.serverTimestamp(),
        i18n: getters.needsTranslation ? await getI18n(getters.language, getters.data, data, ['cardDescription', 'detailDescription']) : null,
      }

      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
      logEvent({ action: 'update', entityType: 'project', entity: project })
    },

    async updateAds({ state }, { organizationId, projectId, data }) {
      const storagePath = `properties/${organizationId}/projects/${projectId}`

      const dataToUpdate = {
        'ads.mainSponsorName': data.mainSponsorName,
        'ads.projectHome.banner': await uploadFileAndGetUrl(storagePath, data.projectHome.banner),
        'ads.projectHome.link': data.projectHome.link,
        'ads.matchDetail.banner': await uploadFileAndGetUrl(storagePath, data.matchDetail.banner),
        'ads.matchDetail.link': data.matchDetail.link,
        'ads.matchesTab.banner': await uploadFileAndGetUrl(storagePath, data.matchesTab.banner),
        'ads.matchesTab.link': data.matchesTab.link,
        'ads.standingsTab.banner': await uploadFileAndGetUrl(storagePath, data.standingsTab.banner),
        'ads.standingsTab.link': data.standingsTab.link,
        'ads.statsTab.banner': await uploadFileAndGetUrl(storagePath, data.statsTab.banner),
        'ads.statsTab.link': data.statsTab.link,
        'ads.knockoutTab.banner': await uploadFileAndGetUrl(storagePath, data.knockoutTab.banner),
        'ads.knockoutTab.link': data.knockoutTab.link,
        'ads.teamsTab.banner': await uploadFileAndGetUrl(storagePath, data.teamsTab.banner),
        'ads.teamsTab.link': data.teamsTab.link,
        'updatedAt': FieldValue.serverTimestamp(),
      }

      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(dataToUpdate)
    },

    async updateTerms({ state, getters }, { organizationId, projectId, defaultLanguage, termsList }) {
      const firebaseData = {
        terms: {
          languages: termsList.map(term => term.id),
          defaultLanguage,
        },
        updatedAt: FieldValue.serverTimestamp(),
      }
      // update project
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firebaseData)

      // Remove terms
      if (getters.terms.length) {
        const termsTodelete = getters.terms.filter(t => !termsList.map(t1 => t1.id).includes(t.id))
        await Promise.all(termsTodelete.map(term => db.collection(`properties/${organizationId}/projects/${projectId}/terms`).doc(term.id).delete()))
      }

      // update terms
      await Promise.all(termsList.map(term => db.collection(`properties/${organizationId}/projects/${projectId}/terms`).doc(term.id).set(term)))
      logEvent({ action: 'update', entityType: 'project', entity: state.dbData })
      return true
    },

    async updatePaymentsConfig(context, { organizationId, projectId, data }) {
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(data)
    },

    async updateEmailSettings(context, { organizationId, project, data }) {
      const { id: projectId } = project
      const storagePath = `properties/${organizationId}/projects/${projectId}`
      if (data.banner) data.banner = await uploadFileAndGetUrl(storagePath, data.banner)
      const firestoreData = {
        emailSettings: data,
        updatedAt: FieldValue.serverTimestamp(),
      }
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update(firestoreData)
      return true
    },
    async archive(context, { organizationId, project }) {
      const { id: projectId } = project
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ active: false })
      logEvent({ action: 'archive', entityType: 'project', entity: project })
      return true
    },
    async unarchive(context, { organizationId, project }) {
      const { id: projectId } = project
      await db.collection(`properties/${organizationId}/projects`).doc(projectId).update({ active: true })
      logEvent({ action: 'unarchive', entityType: 'project', entity: project })
      return true
    },
    async publish({ state, getters }, { organizationId, projectId }) {
      const projectRef = db.collection(`properties/${organizationId}/projects`).doc(projectId)
      projectRef.update({ published: true, publishedAt: FieldValue.serverTimestamp() })
      if (!getters.doNotSendNotificationOnPublish) {
        callBackend('notifications/push/create', {
          scope: 'organization',
          context: { organizationId, projectId },
          data: {
            isCustom: true,
            title: i18n.t('pushNotifications.transactional.projectPublished.title', [state.dbData.name]),
            body: i18n.t('pushNotifications.transactional.projectPublished.body', [state.dbData.name]),
            linkType: 'projectDetail',
          },
        })
        // logNotification({ scope: 'organization', action: 'publish', entityType: 'project', entity: state.dbData })
      }
      logEvent({ action: 'update', entityType: 'project', entity: { ...state.dbData, published: true, publishedAt: FieldValue.serverTimestamp() } })
      return true
    },

    async openCompetition(context, { organizationId, projectId }) {
      await db.collection(`properties/${organizationId}/projects/`).doc(projectId).update({ isOpen: true })
      return true
    },

    // Delete
    async delete(context, { organizationId, project }) {
      const { id: projectId } = project
      await callBackend('recursive-delete', { path: `properties/${organizationId}/projects/${projectId}` })
      logEvent({ action: 'delete', entityType: 'project', entity: project })
      return true
    },

    /*
    * Enrollments and Payments
    */
    approveEnrollment(context, { organizationId, projectId, enrollmentId }) {
      return callBackend('projects/users/approve-enrollment', { organizationId, projectId, enrollmentId, role: rolesEnum.PLAYER })
    },
    rejectEnrollment(context, { organizationId, projectId, enrollmentId }) {
      return callBackend('projects/users/reject-enrollment', { organizationId, projectId, enrollmentId, role: rolesEnum.PLAYER })
    },
    async requestPayment({ state }, { organizationId, projectId, enrollment, discount }) {
      const { id: enrollmentId, userId, name } = enrollment
      // update enrollment document
      const data = {
        status: 'pendingPayment',
        ...(discount.value && (discount.type === 'percent' ? { 'info.discountPercent': discount.value } : { 'info.discountAmount': discount.value })),
      }
      await db
        .collection(`properties/${organizationId}/projects/${projectId}/enrollments`)
        .doc(enrollmentId)
        .update(data)
      // update purchase document(s)
      await db.collection('purchases').doc(enrollment.purchaseId).update({ paymentStatus: 'requested' })
      // send notification
      callBackend(
        'notifications/push/create',
        {
          scope: 'user',
          data: {
            isCustom: true,
            title: i18n.t('project.enrollments.pushNotificationTitle'),
            body: i18n.t('project.enrollments.pushNotificationBody', [name ?? '', state.dbData.name]),
            linkType: 'pendingPayments',
          },
          context: {
            organizationId,
            projectId,
            userId,
          },
        },
      )
    },
    async unrequestPayment(context, { organizationId, projectId, enrollment }) {
      const { id: enrollmentId } = enrollment
      // update enrollment document
      await db
        .collection(`properties/${organizationId}/projects/${projectId}/enrollments`)
        .doc(enrollmentId)
        .update({ status: 'pendingApproval' })
      // update purchase document(s)
      await db.collection('purchases').doc(enrollment.purchaseId).update({ paymentStatus: 'notRequested' })
    },

    /*
    * Stripe connected accounts
    */
    readStripeAccountInfo: async ({ state }, { accountId }) => {
      try {
        const stripeAccount = await callBackend('stripe/accounts/read', { accountId })
        return {
          // Stripe data
          ...stripeAccount,
          // Custom properties
          name: stripeAccount.email || stripeAccount.business_profile.url || stripeAccount.business_profile.name || i18n.t('filters.incomplete'),
          get status() {
            if (!stripeAccount.details_submitted) return 'incomplete'
            if (!stripeAccount.charges_enabled) return 'paymentsDisabled'
            if (!stripeAccount.payouts_enabled) return 'payoutsDisabled'
            return 'complete'
          },
        }
      } catch (err) { return null }
    },
    createStripeAccount: (context, { organizationId }) => callBackend('stripe/accounts/create', { organizationId }),
    getStripeAccountOnboardingLink: (context, { accountId }) => callBackend('stripe/accounts/get-onboarding-link', { accountId }),
  },
}
