import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'schedule',
    name: 'calendar-schedule',
    component: () => import('@/modules/calendar/ScheduleCalendar'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.schedule')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.schedule'),
    },
    props: true,
  },
  {
    path: 'create-event/:type/:date',
    name: 'calendar-create-event',
    component: () => import('@/modules/calendar/EditEvent'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.createActivity')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.createActivity'),
    },
    props: true,
  },
  {
    path: 'edit-event/:eventId',
    name: 'calendar-edit-event',
    component: () => import('@/modules/calendar/Event'),
    redirect: { name: 'event-edit' },
    children: [
      {
        path: 'applications',
        name: 'event-edit',
        component: () => import('@/modules/calendar/EditEvent'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.editActivity')} - OWQLO`,
          headerTitle: () => i18n.t(`project.academy.calendar.${store.getters['activity/data'].type}Header`),
        },
        props: true,
      },
      {
        path: 'attendance',
        name: 'attendance-event',
        component: () => import('@/modules/calendar/AttendanceList'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.calendar')} - OWQLO`,
          headerTitle: () => store.getters['activity/data'].name,
        },
        props: true,
      },
    ],
  },
  {
    path: 'edit-event-guild-match/:guildMatchActivityId',
    name: 'calendar-edit-event-guild-match',
    component: () => import('@/modules/guildMatches/EditGuildMatchActivity'),
    redirect: { name: 'event-edit-guild-match' },
    props: true,
    children: [
      {
        path: 'information',
        name: 'event-edit-guild-match',
        component: () => import('@/modules/guildMatches/EditEventGuildMatch'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.editActivity')} - OWQLO`,
          headerTitle: () => i18n.t('project.academy.calendar.matchHeader'),
        },
        props: true,
      },
      {
        path: 'tickets',
        name: 'tickets-edit-guild-match',
        component: () => import('@/modules/guildMatches/EditTicketsGuildMatch'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.calendar')} - OWQLO`,
          headerTitle: () => i18n.t('project.academy.calendar.matchHeader'),
        },
        props: true,
      },
    ],
  },
]
